import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getTopSkipReasonsUBI = (
  payload: UserBIFilters
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/UserBI/topskipreasonse/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalequipmentcomplianceUBI = (
  payload: UserBIFilters
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/UserBI/gettotalequipmentcompliance/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalroutecountUBI = (
  payload: UserBIFilters
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/UserBI/gettotalroutecount/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalroutebystatuspersentageUBI = (
  payload: UserBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/UserBI/gettotalroutebystatuspersentage/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalroutechartcountUBI = (
  payload: UserBIFilters
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/UserBI/gettotalroutechartcount/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

// export const avginspectionperdaybyusercountUBI = (
//   payload: UserBIFilters
// ): Promise<APIResponse<number>> =>
//   request
//     .post(`${apiURL}/UserBI/avginspectionperdaybyusercount/`, payload)
//     .set("Authorization", token())
//     .then((r: { body: any }) => r.body).catch((err) => errorCallback(err.response));

export const inspectionperdaybyuserUBI = (
  payload: UserBIFilters
): Promise<APIResponse<ReportAverageGraph>> =>
  request
    .post(`${apiURL}/UserBI/inspectionperdaybyuser/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMTTRUBI = (
  payload: UserBIFilters
): Promise<APIResponse<ReportXY>> =>
  request
    .post(`${apiURL}/UserBI/getmttr/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMTTRCountUBI = (
  payload: UserBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/UserBI/getmttrcount/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));
