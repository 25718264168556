import { ScheduleRouteStatusEnums, convertDateTimeToUtcDate } from "./../utility/Common";
import { observable, flow, computed } from "mobx";
import * as api from "../utility/dm.api";
import moment from "moment";
import { IpCriticalityEnums } from "../utility/Common";
import { clone } from "ramda";
import { getFacultyTeamsUsers } from "../utility/dm.api";

class ReportStore {
  @observable errorMessage: string | null = null;

  @observable dashboardAssetCount: number = 0;
  @observable getDashboardAssetCountLoading: boolean = false;
  getDashboardAssetCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardAssetCountLoading = true;

    this.dashboardAssetCount = 0;
    try {
      const response: APIResponse<number> = yield api.getassertcount(
        payload
      );
      this.dashboardAssetCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardAssetCountLoading = false;
    }
  }).bind(this);

  @observable dashboardUserCount: number = 0;
  @observable getDashboardUserCountLoading: boolean = false;
  getDashboardUserCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardUserCountLoading = true;

    this.dashboardUserCount = 0;
    try {
      const response: APIResponse<number> = yield api.getusercount(payload);
      this.dashboardUserCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardUserCountLoading = false;
    }
  }).bind(this);

  @observable dashboardEquipmentCount: number = 0;
  @observable getDashboardEquipmentCountLoading: boolean = false;
  getDashboardEquipmentCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardEquipmentCountLoading = true;
    this.dashboardEquipmentCount = 0;

    try {
      const response: APIResponse<number> = yield api.getequipmentcount(payload);
      this.dashboardEquipmentCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardEquipmentCountLoading = false;
    }
  }).bind(this);

  @observable dashboardIPCount: number = 0;
  @observable getDashboardIPCountLoading: boolean = false;
  getDashboardIPCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardIPCountLoading = true;
    this.dashboardIPCount = 0;

    try {
      const response: APIResponse<number> = yield api.getipcount(payload);
      this.dashboardIPCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardIPCountLoading = false;
    }
  }).bind(this);

  @observable dashboardMTTR: ReportXY[] = [];
  @observable getDashboardMTTRLoading: boolean = false;
  getDashboardMTTR = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardMTTRLoading = true;
    this.dashboardMTTR = [];

    try {
      const response: APIResponse<ReportXY[]> = yield api.getmttr(payload);
      this.dashboardMTTR = response.data;
    } catch (error) {
    } finally {
      this.getDashboardMTTRLoading = false;
    }
  }).bind(this);

  @computed
  get dashboardMTTRComputed() {
    // return dummyData;
    return this.dashboardMTTR.map((item: ReportXY) => [
      moment(item.x).valueOf(),
      item.y,
    ]);
  }
  @computed
  get dashboardMTTRTotalCountComputed() {
    // return dummyData.reduce((accum,item) => accum + item.y, 0);
    const avg: number = parseFloat(
      (
        this.dashboardMTTR.reduce((accum, item) => accum + item.y, 0) /
        this.dashboardMTTR.length /
        60
      ).toFixed(2)
    );
    return isNaN(avg) ? 0 : avg;
  }

  @observable dashboardMTBA: ReportXY[] = [];
  @observable getDashboardMTBALoading: boolean = false;
  getDashboardMTBA = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardMTBALoading = true;

    this.dashboardMTBA = [];
    try {
      const response: APIResponse<ReportXY[]> = yield api.getmtba(payload);
      this.dashboardMTBA = response.data;
    } catch (error) {
    } finally {
      this.getDashboardMTBALoading = false;
    }
  }).bind(this);
  @computed
  get dashboardMTBAComputed() {
    // return dummyData;
    return this.dashboardMTBA.map((item: ReportXY) => [
      moment(item.x).valueOf(),
      item.y,
    ]);
  }
  @computed
  get dashboardMTBATotalCountComputed() {
    // return dummyData.reduce((accum,item) => accum + item.y, 0);
    const avg: number = parseFloat(
      (
        this.dashboardMTBA.reduce((accum, item) => accum + item.y, 0) /
        this.dashboardMTBA.length /
        60
      ).toFixed(2)
    );
    return isNaN(avg) ? 0 : avg;
  }

  @observable dashboardRouteCount: number = 0;
  @observable getDashboardRouteCountLoading: boolean = false;
  getDashboardRouteCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardRouteCountLoading = true;
    this.dashboardRouteCount = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalroutecount(payload);
      this.dashboardRouteCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardRouteCountLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutePercentageInProgress: number = 0;
  @observable getDashboardRoutePercentageInProgressLoading: boolean = false;
  getDashboardRoutePercentageInProgress = flow(function* (
    this: ReportStore,
    startDate: string,
    endDate: string
  ) {
    this.getDashboardRoutePercentageInProgressLoading = true;
    this.dashboardRoutePercentageInProgress = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentage(
        this.changeDateTimeUTC(startDate),
        this.changeDateTimeUTC(endDate),
        "5F9CBBF4-F9AE-4E4A-AA59-0A2F5C4FD26F"
      );
      this.dashboardRoutePercentageInProgress = response.data;
    } catch (error) {
    } finally {
      this.getDashboardRoutePercentageInProgressLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutePercentageSkipped: number = 0;
  @observable getDashboardRoutePercentageSkippedLoading: boolean = false;
  getDashboardRoutePercentageSkipped = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardRoutePercentageSkippedLoading = true;
    this.dashboardRoutePercentageSkipped = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentage({...payload, statusId: "4F6614E2-47B6-497A-94EA-1D77B01D811C"});
      this.dashboardRoutePercentageSkipped = response.data;
    } catch (error) {
    } finally {
      this.getDashboardRoutePercentageSkippedLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutePercentageMissed: number = 0;
  @observable getDashboardRoutePercentageMissedLoading: boolean = false;
  getDashboardRoutePercentageMissed = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardRoutePercentageMissedLoading = true;
    this.dashboardRoutePercentageMissed = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentage({...payload, statusId: "FCA7ED1B-1263-4C50-BCD7-56F725DD78FF"});
      this.dashboardRoutePercentageMissed = response.data;
    } catch (error) {
    } finally {
      this.getDashboardRoutePercentageMissedLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutesObservationClose: [] = [];
  @observable dashboardRoutesObservationOpen: [] = [];
  @observable dashboardRoutesObservationCloseLoading: boolean = false;
  @computed
  get closedObservationCount() {
    let totalCount = 0;
    this.dashboardRoutesObservationClose.map((item) => {
      totalCount = totalCount + item[1];
  });
  return totalCount;
  }
  get openObservationCount() {
    let totalCount = 0;
    this.dashboardRoutesObservationOpen.map((item) => {
      totalCount = totalCount + item[1];
  });
  return totalCount;
  }
  @computed get MinMaxObservationOpen(){
    return this.dashboardRoutesObservationOpen.reduce((acc, curr) => {
       acc.min = Math.min(acc.min, curr[1]);
       acc.max = Math.max(acc.max, curr[1]);
       return acc;
     }, {min: 0, max: 0});
   }
  @computed get MinMaxObservationClose(){
    return this.dashboardRoutesObservationClose.reduce((acc, curr) => {
       acc.min = Math.min(acc.min, curr[1]);
       acc.max = Math.max(acc.max, curr[1]);
       return acc;
     }, {min: 0, max: 0});
   }
  @computed get DashbaordClosedObservationTrend() {
    const dashboardClosedObservation: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardRoutesObservationClose.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      dashboardClosedObservation.x.push(dayOfWeek);
      dashboardClosedObservation.y.push(x[1]);
    });
    return dashboardClosedObservation;
  }
  @computed get DashboardOpenObservationTrend() {
    const dashboardOpenObservation: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardRoutesObservationOpen.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      dashboardOpenObservation.x.push(dayOfWeek);
      dashboardOpenObservation.y.push(x[1]);
    });
    return dashboardOpenObservation;
  }
  getDashboardRoutesObservationReport = flow(function* (
    this: ReportStore,
    payload: EquipmentDashBoardReportsPayload,
    statusClose: string,
    statusOpen: string
  ) {
    this.dashboardRoutesObservationCloseLoading = true;
    this.dashboardRoutesObservationClose = [];

    try {
      const response: APIResponse<[]> = yield api.getDashboardObservation({
        ...payload,
        statusId: statusClose
      });
      this.dashboardRoutesObservationClose = response.data;
      const responseOpen: APIResponse<[]> = yield api.getDashboardObservation({
        ...payload,
        statusId: statusOpen
      });
      this.dashboardRoutesObservationOpen = responseOpen.data;
    } catch (error) {
    } finally {
      this.dashboardRoutesObservationCloseLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutesActionClose: [] = [];
  @observable dashboardRoutesActionOpen: [] = [];
  @observable dashboardRoutesActionCloseLoading: boolean = false;
  @computed
  get closedActionCount() {
    let totalCount = 0;
    this.dashboardRoutesActionClose.map((item) => {
      totalCount = totalCount + item[1];
  });
  return totalCount;
  }
  get openActionCount() {
    let totalCount = 0;
    this.dashboardRoutesActionOpen.map((item) => {
      totalCount = totalCount + item[1];
  });
  return totalCount;
  }
  @computed get MinMaxActionsOpen(){
    return this.dashboardRoutesActionOpen.reduce((acc, curr) => {
       acc.min = Math.min(acc.min, curr[1]);
       acc.max = Math.max(acc.max, curr[1]);
       return acc;
     }, {min: 0, max: 0});
   }
  @computed get MinMaxActionsClose(){
    return this.dashboardRoutesActionClose.reduce((acc, curr) => {
       acc.min = Math.min(acc.min, curr[1]);
       acc.max = Math.max(acc.max, curr[1]);
       return acc;
     }, {min: 0, max: 0});
   }
  @computed get DashbaordClosedActionsTrend() {
    const dashboardClosedActions: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardRoutesActionClose.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      dashboardClosedActions.x.push(dayOfWeek);
      dashboardClosedActions.y.push(x[1]);
    });
    return dashboardClosedActions;
  }
  @computed get DashbaordOpenActionsTrend() {
    const dashboardOpenActions: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardRoutesActionOpen.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      dashboardOpenActions.x.push(dayOfWeek);
      dashboardOpenActions.y.push(x[1]);
    });
    return dashboardOpenActions;
  }
  getDashboardRoutesActionReport = flow(function* (
    this: ReportStore,
    payload: EquipmentDashBoardReportsPayload,
    statusClose: string,
    statusOpen: string
  ) {
    this.dashboardRoutesActionCloseLoading = true;
    this.dashboardRoutesActionClose = [];

    try {
      const response: APIResponse<[]> = yield api.getDashboardObservation({
        ...payload,
        statusId: statusClose
      });
      this.dashboardRoutesActionClose = response.data;
      const responseOpen: APIResponse<[]> = yield api.getDashboardObservation({
        ...payload,
        statusId: statusOpen
      });
      this.dashboardRoutesActionOpen = responseOpen.data;
    } catch (error) { 
    } finally {
      this.dashboardRoutesActionCloseLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutesAlertsClose: []=[];
  @observable dashboardRoutesAlertOpen: [] = [];
  @observable dashboardRoutesAlertCloseLoading: boolean = false;
  @computed
  get closedAlertCount() {
    let totalCount = 0;
    this.dashboardRoutesAlertsClose.map((item) => {
      totalCount = totalCount + item[1];
  });
  return totalCount;
  }
  get openAlertCount() {
    let totalCount = 0;
    this.dashboardRoutesAlertOpen.map((item) => {
      totalCount = totalCount + item[1];
  });
  return totalCount;
  }
    @computed get MinMaxAlertOpen(){
    return this.dashboardRoutesAlertOpen.reduce((acc, curr) => {
       acc.min = Math.min(acc.min, curr[1]);
       acc.max = Math.max(acc.max, curr[1]);
       return acc;
     }, {min: 0, max: 0});
   }
  @computed get MinMaxAlertClose(){
    return this.dashboardRoutesAlertsClose.reduce((acc, curr) => {
       acc.min = Math.min(acc.min, curr[1]);
       acc.max = Math.max(acc.max, curr[1]);
       return acc;
     }, {min: 0, max: 0});
   }
  @computed get DashbaordClosedAlertsTrend() {
    const dashboardClosedAlerts: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardRoutesAlertsClose.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      dashboardClosedAlerts.x.push(dayOfWeek);
      dashboardClosedAlerts.y.push(x[1]);
    });
    return dashboardClosedAlerts;
  }
  @computed get DashbaordOpenAlertsTrend() {
    const dashboardOpenAlerts: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardRoutesAlertOpen.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      dashboardOpenAlerts.x.push(dayOfWeek);
      dashboardOpenAlerts.y.push(x[1]);
    });
    return dashboardOpenAlerts;
  }
  getDashboardRoutesAlertReport = flow(function* (
    this: ReportStore,
    payload: EquipmentDashBoardReportsPayload,
  ) {
    this.dashboardRoutesAlertCloseLoading = true;
    this.dashboardRoutesAlertsClose = [];
    this.dashboardRoutesAlertOpen = [];

    try {
      const response: APIResponse<[]> = yield api.getDashboardAlerts({
        ...payload,
        isInAlarm: false
      });
      this.dashboardRoutesAlertsClose = response.data;
      const responseOpen: APIResponse<[]> = yield api.getDashboardAlerts({
        ...payload,
        isInAlarm: true
      });
      this.dashboardRoutesAlertOpen = responseOpen.data;
    } catch (error) { 
    } finally {
      this.dashboardRoutesAlertCloseLoading = false;
    }
  }).bind(this);

  @observable dashboardHealthData: [] = [];
  @observable dashboardHealthLoading: boolean = false;
  @computed
  get DashboardHealthCount() {
    const totalCompliance: number =   this.dashboardHealthData.reduce((acc, curr) => {
      return acc + curr[1];
    }, 0);
    const averageHealth: number = totalCompliance /this.dashboardHealthData.length;
    return isNaN(averageHealth) ? 0 : averageHealth.toFixed(0);
  };

  @computed get MinMaxHealthData(){
   return this.dashboardHealthData.reduce((acc, curr) => {
      acc.min = Math.min(acc.min, curr[1]);
      acc.max = Math.max(acc.max, curr[1]);
      return acc;
    }, {min: 0, max: 0});
  }

  @computed get DashboardHealthTrend() {
    const healthTrend: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardHealthData.forEach((x) => {
      healthTrend.x.push(x[0]);
      healthTrend.y.push(Math.round(x[1]));
    });
    return healthTrend;
  }

  getDashboardHealthReport = flow(function* (
    this: ReportStore,
    payload: EquipmentDashBoardReportsPayload
  ) {
    this.dashboardHealthLoading = true;
    this.dashboardHealthData = [];
    try {
      const response: APIResponse<[]> = yield api.getDashboardHealthReport(payload);
      this.dashboardHealthData = response.data;
    } catch (error) {
    } finally {
      this.dashboardHealthLoading = false;
    }
  }).bind(this);

  @observable dashboardComplianceReportData: [] = [];
  @observable dashboardComplianceReportDataLoading: boolean = false;
  @computed get DashboardComplianceReport() {
   const totalCompliance: number =   this.dashboardComplianceReportData.reduce((acc, curr) => {
        return acc + curr[1];
      }, 0);
      const avaerageCompliance =  totalCompliance /this.dashboardComplianceReportData.length;
      return isNaN(avaerageCompliance) ? 0 : avaerageCompliance.toFixed(0);
    }
  @computed get MinMaxComplianceData(){
    return this.dashboardComplianceReportData.reduce((acc, curr) => {
      acc.min = Math.min(acc.min, curr[1]);
      acc.max = Math.max(acc.max, curr[1]);
      return acc;
    }, {min: 0, max: 0});
  }
  @computed get DashboardComplianceTrend() {
    const complianceTrend: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardComplianceReportData.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("ddd");
      complianceTrend.x.push(dayOfWeek);
      complianceTrend.y.push(Math.round(x[1]));
    });
    return complianceTrend;
  }
  getDashboardComplianceReport = flow(function* (
    this: ReportStore,
    payload: EquipmentDashBoardReportsPayload
  ) {
    this.dashboardComplianceReportDataLoading = true;
    this.dashboardComplianceReportData = [];;
    try {
      const response: APIResponse<[]> = yield api.getDashboardComplianceReport(payload);
      this.dashboardComplianceReportData = response.data;
    } catch (error) {
    } finally {
      this.dashboardComplianceReportDataLoading = false;
    }
  }).bind(this);
 
  @observable dashboardRoutePercentageCompleted: number = 0;
  @observable getDashboardRoutePercentageCompletedLoading: boolean = false;
  getDashboardRoutePercentageCompleted = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardRoutePercentageCompletedLoading = true;
    this.dashboardRoutePercentageCompleted = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentage({...payload, statusId: "0F3134BE-DB07-4444-ABB2-878853FC4D48"});
      this.dashboardRoutePercentageCompleted = response.data;
    } catch (error) {
    } finally {
      this.getDashboardRoutePercentageCompletedLoading = false;
    }
  }).bind(this);

  @observable dashboardRoutePercentagePending: number = 0;
  @observable getDashboardRoutePercentagePendingLoading: boolean = false;
  getDashboardRoutePercentagePending = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardRoutePercentagePendingLoading = true;
    this.dashboardRoutePercentagePending = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentage({...payload, statusId: "B5855293-7BF1-474A-8636-B2344E8B3995"});
      this.dashboardRoutePercentagePending = response.data;
    } catch (error) {
    } finally {
      this.getDashboardRoutePercentagePendingLoading = false;
    }
  }).bind(this);

  @observable dashboardTotalRouteChartCount: ReportStartDateTotal[] = [];
  @observable getDashboardTotalRouteChartCountLoading: boolean = false;
  getDashboardTotalRouteChartCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardTotalRouteChartCountLoading = true;
    this.dashboardTotalRouteChartCount = [];

    try {
      const response: APIResponse<
        ReportStartDateTotal[]
      > = yield api.gettotalroutechartcount(payload);
      this.dashboardTotalRouteChartCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalRouteChartCountLoading = false;
    }
  }).bind(this);

  @observable dashboardTotalRegisteredEquipmentCount: number = 0;
  @observable getDashboardTotalRegisteredEquipmentCountLoading: boolean = false;
  getDashboardTotalRegisteredEquipmentCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardTotalRegisteredEquipmentCountLoading = true;

    this.dashboardTotalRegisteredEquipmentCount = 0;
    try {
      const response: APIResponse<number> = yield api.gettotalregisterequipmentcount(payload);
      this.dashboardTotalRegisteredEquipmentCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalRegisteredEquipmentCountLoading = false;
    }
  }).bind(this);

  @observable dashboardTotalRegisteredAlarmEquipmentCount: number = 0;
  @observable
  getDashboardTotalRegisteredAlarmEquipmentCountLoading: boolean = false;
  getDashboardTotalRegisteredAlarmEquipmentCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardTotalRegisteredAlarmEquipmentCountLoading = true;
    this.dashboardTotalRegisteredAlarmEquipmentCount = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalregisterequipmentinalarmstatecount(payload);
      this.dashboardTotalRegisteredAlarmEquipmentCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalRegisteredAlarmEquipmentCountLoading = false;
    }
  }).bind(this);

  @observable dashboardTotalBreakDownSavedCount: number = 0;
  @observable getDashboardTotalBreakDownSavedCountLoading: boolean = false;
  getDashboardTotalBreakDownSavedCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardTotalBreakDownSavedCountLoading = true;
    this.dashboardTotalBreakDownSavedCount = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalequipmentbreakdownsavedcount(payload);
      this.dashboardTotalBreakDownSavedCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalBreakDownSavedCountLoading = false;
    }
  }).bind(this);

  @observable dashboardAverageResponseTimeCount: number = 0;
  @observable getDashboardAverageResponseTimeCountLoading: boolean = false;
  getDashboardAverageResponseTimeCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardAverageResponseTimeCountLoading = true;
    this.dashboardAverageResponseTimeCount = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalequipmentavrageresponsetime(payload);
      this.dashboardAverageResponseTimeCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardAverageResponseTimeCountLoading = false;
    }
  }).bind(this);

  @observable dashboardEquipmentComplianceCount: number = 0;
  @observable getDashboardEquipmentComplianceCountLoading: boolean = false;
  getDashboardEquipmentComplianceCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardEquipmentComplianceCountLoading = true;
    this.dashboardEquipmentComplianceCount = 0;

    try {
      const response: APIResponse<number> = yield api.gettotalequipmentcompliance(payload);
      this.dashboardEquipmentComplianceCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardEquipmentComplianceCountLoading = false;
    }
  }).bind(this);

  @observable dashboardTotalAnonamyByCriticalityCount: number = 0;
  @observable
  getDashboardTotalAnonamyByCriticalityCountLoading: boolean = false;
  getDashboardTotalAnonamyByCriticalityCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardTotalAnonamyByCriticalityCountLoading = true;

    this.dashboardTotalAnonamyByCriticalityCount = 0;
    try {
      const response: APIResponse<number> = yield api.gettotalcriticaltycount(payload);
      this.dashboardTotalAnonamyByCriticalityCount = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalAnonamyByCriticalityCountLoading = false;
    }
  }).bind(this);

  @observable dashboardAnonamyByCriticalityCount: ReportXY[] = [];
  @observable getDashboardAnonamyByCriticalityCountLoading: boolean = false;
  getDashboardAnonamyByCriticalityCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardAnonamyByCriticalityCountLoading = true;
    this.dashboardAnonamyByCriticalityCount = [];

    try {

      const response: APIResponse<ReportXY[]> = yield api.criticaltycount(payload);
      
        var data: ReportXY[] = [];
        var highValue:ReportXY = response.data.find(x=>x.x.toLowerCase() === 'FCA7ED1B-1263-4C50-BCD7-56F725DD78FF'.toLowerCase())!;
        var medValue:ReportXY = response.data.find(x=>x.x.toLowerCase() === 'FCA7ED1B-1263-4C50-BCD7-56F7266D78FF'.toLowerCase())!;
        var lowValue:ReportXY = response.data.find(x=>x.x.toLowerCase() === 'FCA7ED1B-1263-4C50-BCD7-5777266D78FF'.toLowerCase())!;
        highValue && data.push({ x: 'Highly Critical', y: highValue.y });
        medValue && data.push({ x: 'Medium Critical', y: medValue.y });
        lowValue && data.push({ x: 'Low Critical', y: lowValue.y });

        this.dashboardAnonamyByCriticalityCount = data;
        

    } catch (error) {
    } finally {
      this.getDashboardAnonamyByCriticalityCountLoading = false;
    }
  }).bind(this);

  @observable dashboardWorkOrdersCount: ReportXY[] = [];
  @observable getDashboardWorkOrdersCountLoading: boolean = false;
  getDashboardWorkOrdersCount = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardWorkOrdersCountLoading = true;
    this.dashboardWorkOrdersCount = [];

    try {
      const response: APIResponse<ReportXY[]> = yield api.workorderscount(payload);
      this.dashboardWorkOrdersCount = response.data;
      // this.dashboardWorkOrdersCount = [{x: 'abc', y: 20},{x: 'def', y: 30},{x: 'xyz', y: 50}];
    } catch (error) {
    } finally {
      this.getDashboardWorkOrdersCountLoading = false;
    }
  }).bind(this);

  @observable dashboardAlarmTrendChart: ReportStartDateTotal[] = [];
  @observable getDashboardAlarmTrendChartLoading: boolean = false;
  getDashboardAlarmTrendChart = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardAlarmTrendChartLoading = true;
    this.dashboardAlarmTrendChart = [];

    try {
      const response: APIResponse<
        ReportStartDateTotal[]
      > = yield api.alarmtrendchart(payload);
      this.dashboardAlarmTrendChart = response.data;
      // this.dashboardAlarmTrendChart = [{x: 'abc', y: 20},{x: 'def', y: 30},{x: 'xyz', y: 50}];
    } catch (error) {
    } finally {
      this.getDashboardAlarmTrendChartLoading = false;
    }
  }).bind(this);
  @computed
  get dashboardAlarmTrendChartComputed() {
    return this.dashboardAlarmTrendChart.map((x: ReportStartDateTotal) => [
      moment(x.startDate).valueOf(),
      x.total,
    ]);
  }

  @observable dashboardTopBadActors: ReportBadActor = { x: [], y: [] };
  @observable getDashboardTopBadActorsLoading: boolean = false;
  getDashboardTopBadActors = flow(function* (
    this: ReportStore,
    payload: MasterDashboardFilter
  ) {
    this.getDashboardTopBadActorsLoading = true;
    this.dashboardTopBadActors = { x: [], y: [] };

    try {
      const response: APIResponse<ReportBadActor> = yield api.topbadactors(payload);
      this.dashboardTopBadActors = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTopBadActorsLoading = false;
    }
  }).bind(this);

  @observable equipmentScheduleRoutesPending:  number = 0;
  @observable getRouteTrendPendingLoading: boolean = false;
  @computed get scheduledRoutesPendingPercentage() {
    const routesPercentage = (this.equipmentScheduleRoutesPending / this.equipmentScheduleRoutesTotal) * 100;
    return isNaN(routesPercentage) ? 0 : routesPercentage.toFixed(0);
  }

  getRouteSchedulePendingByEquipment = flow(function* (this: ReportStore, filters: ScheduledRoutesTrendPayload) {
    this.getRouteTrendPendingLoading = true;
    this.equipmentScheduleRoutesPending = 0;
    try {
      const response: APIResponse<number> = yield api.getRouteScheduleByEquipment({...filters, statusId: 'B5855293-7BF1-474A-8636-B2344E8B3995'});
      this.equipmentScheduleRoutesPending = response.data;
    } catch (error) {
    } finally {
      this.getRouteTrendPendingLoading = false;
    }
  }).bind(this);

  @observable equipmentScheduleRoutesCompleted:  number = 0;
  @observable getRouteTrendCompletedLoading: boolean = false;
  @computed get scheduledRoutesCompletedPercentage() {
    const routesPercentage = (this.equipmentScheduleRoutesCompleted / this.equipmentScheduleRoutesTotal) * 100;
    return isNaN(routesPercentage) ? 0 : routesPercentage.toFixed(0);
  }
  getRouteScheduleCompletedByEquipment = flow(function* (this: ReportStore, filters: ScheduledRoutesTrendPayload) {
    this.getRouteTrendCompletedLoading = true;
    this.equipmentScheduleRoutesCompleted = 0;
    try {
      const response: APIResponse<number> = yield api.getRouteScheduleByEquipment({...filters, statusId: '0F3134BE-DB07-4444-ABB2-878853FC4D48'});
      this.equipmentScheduleRoutesCompleted = response.data;
    } catch (error) {
    } finally {
      this.getRouteTrendCompletedLoading = false;
    }
  }).bind(this);

  @observable equipmentScheduleRoutesSkipped:  number = 0;
  @observable getRouteTrendSkippedLoading: boolean = false;
  @computed get scheduledRoutesSkippedPercentage() {
    const routesPercentage = (this.equipmentScheduleRoutesSkipped / this.equipmentScheduleRoutesTotal) * 100;
    return isNaN(routesPercentage) ? 0 : routesPercentage.toFixed(0);
  }
  getRouteScheduleSkippedByEquipment = flow(function* (this: ReportStore, filters: ScheduledRoutesTrendPayload) {
    this.getRouteTrendSkippedLoading = true;
    this.equipmentScheduleRoutesSkipped = 0;
    try {
      const response: APIResponse<number> = yield api.getRouteScheduleByEquipment({...filters, statusId: ScheduleRouteStatusEnums[1].id});
      this.equipmentScheduleRoutesSkipped = response.data;
    } catch (error) {
    } finally {
      this.getRouteTrendSkippedLoading = false;
    }
  }).bind(this);

  @observable equipmentScheduleRoutesTotal:  number = 0;
  @observable getRouteTrendTotalLoading: boolean = false;

  getRouteScheduleTotalByEquipment = flow(function* (this: ReportStore, filters: ScheduledRoutesTrendPayload) {
    this.getRouteTrendTotalLoading = true;
    this.equipmentScheduleRoutesTotal = 0;
    try {
      const response: APIResponse<number> = yield api.getRouteScheduleByEquipment(filters);
      this.equipmentScheduleRoutesTotal = response.data;
    } catch (error) {
    } finally {
      this.getRouteTrendTotalLoading = false;
    }
  }).bind(this);

  @observable dashBoardReportMasterFilter: MasterDashboardFilter = {
      startdate: this.changeDateTimeUTC("2005-09-14"),
      enddate: this.changeDateTimeUTC("2025-09-14"),
      userIds: []
  };
  @observable dashboarReportMasterFilterLoading: boolean = false;
  getDashBoardReportMasterFilter = flow(function* (
    this: ReportStore,
    dashBoardReportMasterFilter: MasterDashboardFilter,
    userIds: string[]
  ) {
    try {
      this.dashBoardReportMasterFilter = {
        ...dashBoardReportMasterFilter,
        userIds: userIds
      };
    } catch (error) {
    } finally {
      this.dashboarReportMasterFilterLoading = false;
    }
  }).bind(this);

  changeDateTimeUTC(date: string): string {
    return convertDateTimeToUtcDate(date);
  }
}

export default ReportStore;
