import { observable, flow } from 'mobx';
import * as api from "../utility/dm.api";

class CustomerReportStore {

  @observable dailyReport: DailyAlarmReport | null = null;
  getDailyReport = flow(function* (this: CustomerReportStore, payload: ReportsPayload) {
    this.dailyReport = null;
    try {
      const response: APIResponse<DailyAlarmReport> = yield api.getalarmsdailyreport(payload);
      this.dailyReport = response.data;
    } catch (error) {

    }
  }).bind(this);

  @observable IpTypeAnomalyData: IpTypeAnomalyReportDataResponse | null = null;
  GetIpTypeAnomalyReportData = flow(function* (this: CustomerReportStore, payload: IpTypeAnomalyReportDataPayload) {
    this.IpTypeAnomalyData = null;
    try {
      const response: APIResponse<IpTypeAnomalyReportDataResponse> = yield api.IpTypeAnomalyReportData(payload);
      this.IpTypeAnomalyData = response.data;
    } catch (error) {

    }
  }).bind(this);

  @observable LastInspectionIpStatusData: LastInspectionIpStatusReportDataResponse | null = null;
  GetLastInspectionIpStatusReportData = flow(function* (this: CustomerReportStore, payload: ReportsPayload) {
    this.LastInspectionIpStatusData = null;
    try {
      const response: APIResponse<LastInspectionIpStatusReportDataResponse> = yield api.LastInspectionIpStatusReportData(payload);
      this.LastInspectionIpStatusData = response.data;
    } catch (error) {

    }
  }).bind(this);

  @observable EquipmentHealthData: EquipmentHealthReportDataResponse | null | 'loading' = null;
  GetEquipmentHealthReportData = flow(function* (this: CustomerReportStore, payload: EquipmentHealthReportDataPayload) {
    this.EquipmentHealthData = 'loading';
    try {
      const response: APIResponse<EquipmentHealthReportDataResponse> = yield api.EquipmentHealthReportData(payload);
      this.EquipmentHealthData = response.data;
    } catch (error) {
      this.EquipmentHealthData = null;
    }
  }).bind(this);

  @observable actionStatusReport: ActionStatusReport[] | null = null;
  getActionStatusReport = flow(function* (this: CustomerReportStore, payload: ActionStatusReportPayload) {
    this.actionStatusReport = null;
    try {
      const response: APIResponse<ActionStatusReport[]> = yield api.getActionStatusRepport(payload);
      this.actionStatusReport = response.data;
    } catch (error) {
    this.actionStatusReport = [];
    }
  }).bind(this);

  @observable IpUtilizationData: IpUtilizationReportResponse[] | null | string = null;
  getIpUtilizationReport = flow(function* (this: CustomerReportStore, payload: IpUtilizationReportPayload) {
    this.IpUtilizationData = 'loading';
    try {
      const response: APIResponse<IpUtilizationReportResponse> = yield api.getIpUtilizationReport(payload);
      this.IpUtilizationData = response.data;   
    } catch (error) {
      this.IpUtilizationData = [];
    }
  }).bind(this);

  @observable RouteScheduleSummaryData: RouteScheduleSummaryResponse[] = [];
  @observable RouteScheduleSummaryLoading: boolean = false;
  getRouteScheduleSummaryData = flow(function* (this: CustomerReportStore, payload: RouteScheduleSummaryPayload) {
    this.RouteScheduleSummaryLoading = true;
    this.RouteScheduleSummaryData = [];
    try {
      const response: APIResponse<RouteScheduleSummaryResponse[]> = yield api.getRouteScheduleSummary(payload);
      this.RouteScheduleSummaryData = response.data;   
    } catch (error) {
      this.IpUtilizationData = [];
    } finally {
      this.RouteScheduleSummaryLoading = false;
    }
  }).bind(this);

}

export default CustomerReportStore;
