import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getalarmsdailyreport = (
  payload: ReportsPayload
): Promise<APIResponse<DailyAlarmReport>> =>
  request
    .post(`${apiURL}/Reporting/GetAlarmedDailyReportData`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const IpTypeAnomalyReportData = (
  payload: IpTypeAnomalyReportDataPayload
): Promise<APIResponse<IpTypeAnomalyReportDataResponse>> =>
  request
    .post(`${apiURL}/Reporting/IpTypeAnomalyReportData`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpUtilizationReport = (
  payload: IpUtilizationReportPayload
): Promise<APIResponse<IpUtilizationReportResponse>> =>
  request
    .post(`${apiURL}/Reporting/iputilizationreport`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const LastInspectionIpStatusReportData = (
  payload: ReportsPayload
): Promise<APIResponse<LastInspectionIpStatusReportDataResponse>> =>
  request
    .post(`${apiURL}/Reporting/LastInspectionIpStatusReportData`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const EquipmentHealthReportData = (
  payload: EquipmentHealthReportDataPayload
): Promise<APIResponse<EquipmentHealthReportDataResponse>> =>
  request
    .post(`${apiURL}/Reporting/EquipmentHealthReportData`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getActionStatusRepport = (
      payload: ActionStatusReportPayload
    ): Promise<APIResponse<ActionStatusReport[]>> =>
      request
        .post(`${apiURL}/Reporting/actionstatuslog`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

 export const getDashboardObservation = (
   payload: EquipmentDashBoardReportsPayload
 ): Promise<APIResponse<[]>> =>
   request
     .post(`${apiURL}/Report/workordersbycategory`, payload)
     .set("Authorization", token())
     .then((r: { body: any }) => r.body)
     .catch((err) => errorCallback(err.response));
  export const getDashboardHealthReport = (
      payload: EquipmentDashBoardReportsPayload
    ): Promise<APIResponse<[]>> =>
      request
        .post(`${apiURL}/Report/healthtrend`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));
    
  export const getDashboardComplianceReport = (
      payload: EquipmentDashBoardReportsPayload
    ): Promise<APIResponse<[]>> =>
      request
        .post(`${apiURL}/Report/equipmentcompliancetrend`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));
        
 export const getDashboardAlerts = (
   payload: DashboardAlertsReportPayload
 ): Promise<APIResponse<[]>> =>
   request
     .post(`${apiURL}/Report/equipmentalarmtrend`, payload)
     .set("Authorization", token())
     .then((r: { body: any }) => r.body)
     .catch((err) => errorCallback(err.response));

export const getRouteScheduleByEquipment = (
  payload: ScheduledRoutesTrendPayload
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/getrouteschedulecountbyequipment`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getRouteScheduleSummary = (
  payload: RouteScheduleSummaryPayload
): Promise<APIResponse<RouteScheduleSummaryResponse[]>> =>
  request
    .post(`${apiURL}/Reporting/routeschedulesummaryreport`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));