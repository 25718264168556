import * as request from "superagent";
import { errorCallback, showSuccessNoti } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getTemplates = (
  payload: PaginatedFiltersListPayload
): Promise<APIResponse<PaginatedListResponse<TemplateListResponse[]>>> =>
  request
    .post(`${apiURL}/Template/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => {
      errorCallback(err.response);
    });

export const deleteTemplate = (
  ID: string,
  recursive: boolean
): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Template/controlactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const saveTemplate = (
  payload: TemplatesPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Template/save`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => {
      errorCallback(err.response);
    });

export const getTemplate = (ID: string): Promise<APIResponse<TemplatesPayload>> =>
  request
    .get(`${apiURL}/Template/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTemplateTypes = (
  templateTypeId: string
): Promise<APIResponse<TemplatePropertiesListResponse[]>> =>
  request
    .get(`${apiURL}/PropertyInfo/getproperties/${templateTypeId}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => {
      errorCallback(err.response);
    });

export const getTemplateRecipes = (
      payload: PaginatedFiltersListPayload
    ): Promise<APIResponse<PaginatedListResponse<TemplateRecipeListResponse[]>>> =>
      request
        .post(`${apiURL}/TemplateRecipe/get`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => {
          errorCallback(err.response);
        });

export const deleteTemplateRecipe = (
          ID: string,
          recursive: boolean
        ): Promise<APIResponse<boolean>> =>
          request
            .get(`${apiURL}/TemplateRecipe/controlactivation/${ID}/${recursive}`)
            .set("Authorization", token())
            .then((r: { body: any }) => {
              showSuccessNoti();
              return r.body;
            })
            .catch((err) => errorCallback(err.response));

export const saveTemplateRecipe = (
  payload: TemplateRecipeRequest
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/TemplateRecipe/save`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => {
      errorCallback(err.response);
    });

export const executeTemplateRecipe = (
  payload: TemplateRecipeExecutionPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/TemplateRun/run`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      return r.body;
    })
    .catch((err) => {
      errorCallback(err.response);
    });        