import * as request from "superagent";
import { errorCallback, showSuccessNoti } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getRights = (roleID: string): Promise<APIResponse<AppRight[]>> =>
    request
        .get(`${apiURL}/Register/getrights/${roleID}`)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

export const GetRolesRights = (payload: PaginatedListPayload): Promise<APIResponse<PaginatedListResponse<RolesRights[]>>> =>
    request
        .post(`${apiURL}/RolesRights/GetRolesRights`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

export const GetRolesRight = (roleID: string): Promise<APIResponse<RolesRights>> =>
    request
        .get(`${apiURL}/RolesRights/GetRolesRight/${roleID}`)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

export const AddRolesRights = (payload: RolesRights): Promise<APIResponse<boolean>> =>
    request
        .post(`${apiURL}/RolesRights/AddRolesRights`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

export const UpdateRolesRights = (payload: RolesRights): Promise<APIResponse<boolean>> =>
    request
        .post(`${apiURL}/RolesRights/UpdateRolesRights`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

export const DeleteRolesRights = (id: string): Promise<APIResponse<boolean>> =>
    request
        .get(`${apiURL}/RolesRights/DeleteRolesRights/${id}`)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));