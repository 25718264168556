import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getAssetStatus = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getassetstatus`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getEquipmentTypes = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getequipmenttypes`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getFrequencies = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getfrequency`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpFaculties = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getipfaculty`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpCriticalities = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getipcriticality`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpTypes = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getiptype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpTools = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getiptools`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpDataTypes = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getipdatatype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpEngineeringUnits = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getipengineeringunit`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getRouteStatus = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getroutestatus`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getRouteScheduleStatus = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getrouteschedulestatus`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getWorkOrdersStatus = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getworkordersstatus`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getDocumentTypes = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getdocumenttypes`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTemplatesTypes = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/gettemplatetype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTemplateRecipeIndustries = (): Promise<
    APIResponse<MasterListResponse[]>
  > =>
    request
      .get(`${apiURL}/MasterTable/getrecipeindustry`)
      .set("Authorization", token())
      .then((r: { body: any }) => r.body)
      .catch((err) => errorCallback(err.response));