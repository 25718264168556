import React from "react";
import { Spinner } from "reactstrap";

interface Props {
  loading: boolean;
  height?: any;
  children: any;
}

export const DataLoader: React.FC<Props> = ({ loading, children, height }) => {

  const parentCss: any = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: height ? height : '100%',
    width: '100%'
  };

  const childCss: any = {
    order: 0,
    flex: '0 1 auto',
    alignSelf: 'center',
  }

  return (
    <>
      {loading && (
        <div style={parentCss}>
          <div style={childCss}>
            <Spinner color="white" size="lg" />
          </div>
        </div>
      )}
      {!loading && children}
    </>
  );
};



