import React from "react";
import "./NavbarUser.scss";
import { useTranslation } from "react-i18next";

const Switch = () => {
  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <div class="switch">
      <input
        type="checkbox"
        id="toggle"
        class="switch-input"
        onChange={(e) => {
          changeLanguageHandler(e.target.checked ? "ar" : "en");
        }}
      />
      <label for="toggle" class="switch-label">
        <span class="switch-circle">
          <span class="switch-text switch-text-ar" style={{ color: "black" }}>
            AR
          </span>
          <span class="switch-text switch-text-en" style={{ color: "black" }}>
            EN
          </span>
        </span>
      </label>
    </div>
  );
};

export default Switch;
