import React, { useEffect } from "react";
import { useStore } from "../stores/index";
import { observer } from "mobx-react-lite";
import {VerifyAuthToken} from "../utility/Common";

export const GetUserData: React.FC = observer((props: any) => {
  const {
    accountStore: {
      currentUser,
      getCurrentUser,
      getUserSubscriptions,
    }
  } = useStore();

  useEffect(() => {
    if(VerifyAuthToken()){
      getCurrentUser();
      getUserSubscriptions();
    }
  }, []);

  return <>{props.children}</>
});
