import { observable, flow, computed } from "mobx";
import moment from "moment";
import * as api from "../utility/dm.api";
import { initPaginateListResponseGlobal } from "utility/Common";

export default class ExploreBIStore {
  @observable errorMessage: string | null = null;

  @observable totalInspectionCount: number = 0;
  @observable totalInspectionCountLoading: boolean = false;
  getTotalInspectionCount = flow(function* (
    this: ExploreBIStore,
    filter: ExploreBIFilters
  ) {
    this.totalInspectionCountLoading = true;

    this.totalInspectionCount = 0;
    try {
      const response: APIResponse<number> = yield api.exploreTotalInspectionCount(
        filter
      );
      this.totalInspectionCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.totalInspectionCountLoading = false;
    }
  }).bind(this);

  @observable totalAnomalyCount: number = 0;
  @observable totalAnomalyCountLoading: boolean = false;
  getTotalAnomalyCount = flow(function* (
    this: ExploreBIStore,
    filter: ExploreBIFilters
  ) {
    this.totalAnomalyCountLoading = true;

    this.totalAnomalyCount = 0;
    try {
      const response: APIResponse<number> = yield api.exploreGetTotalAnomalyCount(
        filter
      );
      this.totalAnomalyCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.totalAnomalyCountLoading = false;
    }
  }).bind(this);

  @observable inspectionData: PaginatedListResponse<any[]> = initPaginateListResponseGlobal<any[]>([]);
  @observable inspectionDataLoading: boolean = false;
  getInspectionData = flow(function* (
    this: ExploreBIStore,
    payload: ExploreBiGetDataRequest
  ) {
    this.inspectionDataLoading = true;
    this.inspectionData = initPaginateListResponseGlobal<any[]>([]);
    try {
      const response: APIResponse<PaginatedListResponse<any[]>> = yield api.exploreGetInspectionData(
        payload
      );
      this.inspectionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.inspectionDataLoading = false;
    }
  }).bind(this);

  @observable inspectionInsightData: any[] = []
  @observable inspectionInsightDataLoading: boolean = false;
  
  getInspectionInsightData = flow(async function* (
    this: ExploreBIStore,
    payload: ExploreBiGetDataRequest,
    ipDetail: any[]
  ) {
      this.inspectionInsightDataLoading = true;
    
    try {
      this.inspectionInsightData = [];
      for (const item of ipDetail) {
        const response: APIResponse<PaginatedListResponse<any[]>> = yield api.exploreGetInspectionData({
          ...payload,
          startDate: item.startDate,
          ipIds: [item.id]
        });
        const existingData = this.inspectionInsightData?.find((data) => data.id === item.id);
          const [singleIpData] = response.data.data;
            if(!existingData){
              this.inspectionInsightData.push({
                id: item.id,
                name: item.name,
                value: singleIpData ? singleIpData.value :"",
                anomaly: singleIpData ? singleIpData.anomaly :"",
                ipDataTypeId: item.ipDataTypeId
              });
            }
        }  
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.inspectionInsightDataLoading = false;
    }
  }).bind(this);

  @observable singleIpInspectionData: PaginatedListResponse<any[]> = initPaginateListResponseGlobal<any[]>([]);
  @observable singleIpInspectionDataLoading: boolean = false;
  getSingleIpInspectionData = flow(function* (
    this: ExploreBIStore,
    payload: ExploreBiGetDataRequest
  ) {
    this.singleIpInspectionDataLoading = true;
    this.singleIpInspectionData = initPaginateListResponseGlobal<any[]>([]);
    try {
      const response: APIResponse<PaginatedListResponse<any[]>> = yield api.exploreGetInspectionData(
        payload
      );
      this.singleIpInspectionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.singleIpInspectionDataLoading = false;
    }
  }).bind(this);

  @observable exploreTopBadActors: any[] = [];
  @observable getExploreTopBadActorsLoading: boolean = false;
  getExploreTopBadActors = flow(function* (
    this: ExploreBIStore,
    filters: ExploreBIFilters
  ) {
    this.getExploreTopBadActorsLoading = true;

    this.exploreTopBadActors = [];
    try {
      const response: APIResponse<any[]> = yield api.exploretopbadactors(
        filters
      );
      this.exploreTopBadActors = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreTopBadActorsLoading = false;
    }
  }).bind(this);

  @observable exploreAlarmTrendChart: ReportStartDateTotal[] = [];
  @observable getExploreAlarmTrendChartLoading: boolean = false;
  getExploreAlarmTrendChart = flow(function* (
    this: ExploreBIStore,
    filter: ExploreBIFilters
  ) {
    this.getExploreAlarmTrendChartLoading = true;

    this.exploreAlarmTrendChart = [];
    try {
      const response: APIResponse<
        ReportStartDateTotal[]
      > = yield api.exploreGetExploreAlarmTrendChart(filter);
      this.exploreAlarmTrendChart = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreAlarmTrendChartLoading = false;
    }
  }).bind(this);
  @computed
  get exploreAlarmTrendChartComputed() {
    return this.exploreAlarmTrendChart.map((x: ReportStartDateTotal) => [
      moment(x.startDate).valueOf(),
      x.total,
    ]);
  }

  @observable exploreTotalRegisteredEquipmentCount: number = 0;
  @observable getExploreTotalRegisteredEquipmentCountLoading: boolean = false;
  getExploreTotalRegisteredEquipmentCount = flow(function* (
    this: ExploreBIStore,
    filters: ExploreBIFilters
  ) {
    this.getExploreTotalRegisteredEquipmentCountLoading = true;

    this.exploreTotalRegisteredEquipmentCount = 0;
    try {
      const response: APIResponse<number> = yield api.exploregettotalregisterequipmentcount(
        filters
      );
      this.exploreTotalRegisteredEquipmentCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreTotalRegisteredEquipmentCountLoading = false;
    }
  }).bind(this);

  @observable exploreTotalRegisteredAlarmEquipmentCount: number = 0;
  @observable
  getExploreTotalRegisteredAlarmEquipmentCountLoading: boolean = false;
  getExploreTotalRegisteredAlarmEquipmentCount = flow(function* (
    this: ExploreBIStore,
    filters: ExploreBIFilters
  ) {
    this.getExploreTotalRegisteredAlarmEquipmentCountLoading = true;
    this.exploreTotalRegisteredAlarmEquipmentCount = 0;

    try {
      const response: APIResponse<number> = yield api.exploregettotalregisterequipmentinalarmstatecount(
        filters
      );
      this.exploreTotalRegisteredAlarmEquipmentCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreTotalRegisteredAlarmEquipmentCountLoading = false;
    }
  }).bind(this);

  @observable exploreTotalBreakDownSavedCount: number = 0;
  @observable getExploreTotalBreakDownSavedCountLoading: boolean = false;
  getExploreTotalBreakDownSavedCount = flow(function* (
    this: ExploreBIStore,
    filters: ExploreBIFilters
  ) {
    this.getExploreTotalBreakDownSavedCountLoading = true;
    this.exploreTotalBreakDownSavedCount = 0;

    try {
      const response: APIResponse<number> = yield api.exploregettotalequipmentbreakdownsavedcount(
        filters
      );
      this.exploreTotalBreakDownSavedCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreTotalBreakDownSavedCountLoading = false;
    }
  }).bind(this);

  @observable exploreAverageResponseTimeCount: number = 0;
  @observable getExploreAverageResponseTimeCountLoading: boolean = false;
  getExploreAverageResponseTimeCount = flow(function* (
    this: ExploreBIStore,
    filters: ExploreBIFilters
  ) {
    this.getExploreAverageResponseTimeCountLoading = true;
    this.exploreAverageResponseTimeCount = 0;

    try {
      const response: APIResponse<number> = yield api.exploregettotalequipmentavrageresponsetime(
        filters
      );
      this.exploreAverageResponseTimeCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreAverageResponseTimeCountLoading = false;
    }
  }).bind(this);

  @observable exploreEquipmentComplianceCount: number = 0;
  @observable getExploreEquipmentComplianceCountLoading: boolean = false;
  getExploreEquipmentComplianceCount = flow(function* (
    this: ExploreBIStore,
    filters: ExploreBIFilters
  ) {
    this.getExploreEquipmentComplianceCountLoading = true;
    this.exploreEquipmentComplianceCount = 0;

    try {
      const response: APIResponse<number> = yield api.exploregettotalequipmentcompliance(
        filters
      );
      this.exploreEquipmentComplianceCount = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getExploreEquipmentComplianceCountLoading = false;
    }
  }).bind(this);
}
