import { observable, flow } from "mobx";
import * as api from "../utility/dm.api";

export default class MasterDataStore {
  @observable loading: MasterDataLoading = {
    assetStatusLoading: false,
    equipmentTypesLoading: false,
    frequenciesLoading: false,
    ipFacultiesLoading: false,
    ipCriticalitiesLoading: false,
    ipTypesLoading: false,
    ipToolsLoading: false,
    ipDataTypesLoading: false,
    ipEngineeringUnitsLoading: false,
    routeStatusLoading: false,
    routeScheduleStatusLoading: false,
    workOrdersStatusLoading: false,
    documentTypesLoading: false,
    templatesTypesLoading: false,
  };
  @observable errorMessage: string | null = null;
  @observable assetStatus: MasterListResponse[] = [];

  getAssetStatus = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, assetStatusLoading: true };
    this.errorMessage = null;
    this.assetStatus = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getAssetStatus();
      this.assetStatus = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, assetStatusLoading: false };
    }
  }).bind(this);

  @observable equipmentTypes: MasterListResponse[] = [];

  getEquipmentTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, equipmentTypesLoading: true };
    this.errorMessage = null;
    this.equipmentTypes = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getEquipmentTypes();
      this.equipmentTypes = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, equipmentTypesLoading: false };
    }
  }).bind(this);

  @observable frequencies: MasterListResponse[] = [];

  getFrequencies = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, frequenciesLoading: true };
    this.errorMessage = null;
    this.frequencies = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getFrequencies();
      this.frequencies = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, frequenciesLoading: false };
    }
  }).bind(this);

  @observable ipFaculties: MasterListResponse[] = [];

  getIpFaculties = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipFacultiesLoading: true };
    this.errorMessage = null;
    this.ipFaculties = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpFaculties();
      this.ipFaculties = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipFacultiesLoading: false };
    }
  }).bind(this);

  @observable ipCriticalities: MasterListResponse[] = [];

  getIpCriticalities = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipCriticalitiesLoading: true };
    this.errorMessage = null;
    this.ipCriticalities = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpCriticalities();
      this.ipCriticalities = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipCriticalitiesLoading: false };
    }
  }).bind(this);

  @observable ipTypes: MasterListResponse[] = [];

  getIpTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipTypesLoading: true };
    this.errorMessage = null;
    this.ipTypes = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpTypes();
      this.ipTypes = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipTypesLoading: false };
    }
  }).bind(this);

  @observable ipTools: MasterListResponse[] = [];

  getIpTools = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipToolsLoading: true };
    this.errorMessage = null;
    this.ipTools = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpTools();
      this.ipTools = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipToolsLoading: false };
    }
  }).bind(this);

  @observable ipDataTypes: MasterListResponse[] = [];

  getIpDataTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipDataTypesLoading: true };
    this.errorMessage = null;
    this.ipDataTypes = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpDataTypes();
      this.ipDataTypes = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipDataTypesLoading: false };
    }
  }).bind(this);

  @observable ipEngineeringUnits: MasterListResponse[] = [];

  getIpEngineeringUnits = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipEngineeringUnitsLoading: true };
    this.errorMessage = null;
    this.ipEngineeringUnits = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpEngineeringUnits();
      this.ipEngineeringUnits = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipEngineeringUnitsLoading: false };
    }
  }).bind(this);

  @observable routeStatus: MasterListResponse[] = [];

  getRouteStatus = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, routeStatusLoading: true };
    this.errorMessage = null;
    this.routeStatus = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getRouteStatus();
      this.routeStatus = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, routeStatusLoading: false };
    }
  }).bind(this);

  @observable routeScheduleStatus: MasterListResponse[] = [];

  getRouteScheduleStatus = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, routeScheduleStatusLoading: true };
    this.errorMessage = null;
    this.routeScheduleStatus = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getRouteScheduleStatus();
      this.routeScheduleStatus = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, routeScheduleStatusLoading: false };
    }
  }).bind(this);

  @observable workOrdersStatus: MasterListResponse[] = [];

  getWorkOrdersStatus = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, workOrdersStatusLoading: true };
    this.errorMessage = null;
    this.workOrdersStatus = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getWorkOrdersStatus();
      this.workOrdersStatus = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, workOrdersStatusLoading: false };
    }
  }).bind(this);

  @observable DocumentTypesData: MasterListResponse[] = [];
  getDocumentTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, documentTypesLoading: true };
    this.errorMessage = null;
    this.DocumentTypesData = [];
    try {
      const response: APIResponse<MasterListResponse[]> =
        yield api.getDocumentTypes();
      this.DocumentTypesData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, documentTypesLoading: false };
    }
  }).bind(this);

  @observable templatesTypes: MasterListResponse[] = [];

  getTemplatesTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, templatesTypesLoading: true };
    this.errorMessage = null;
    this.templatesTypes = [];
    try {
      const response: APIResponse<MasterListResponse[]> =
        yield api.getTemplatesTypes();
      this.templatesTypes = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, templatesTypesLoading: false };
    }
  }).bind(this);

  @observable templateIndustries: MasterListResponse[] = [];

  getTemplateRecipeIndustries = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, templateIndustriesLoading: true };
    this.errorMessage = null;
    this.templateIndustries = [];
    try {
      const response: APIResponse<MasterListResponse[]> =
        yield api.getTemplateRecipeIndustries();
      this.templateIndustries = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, templateIndustriesLoading: false };
    }
  }).bind(this);
}
