import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import { I18nextProvider } from "react-i18next";
import i18next, { use } from "i18next";
// import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
// import "./@fake-db"
import { store as mobxStore, StoreContext } from "./stores/index";
import { GetUserData } from "./components/GetUserData";
import en from "./il8n/en/common.json";
import ar from "./il8n/ar/common.json";
import { initReactI18next } from "react-i18next";

const LazyApp = lazy(() => import("./App"));
const language = navigator.language.split("-")[0];

i18next.use(initReactI18next).init({
  lng: language || "en",
  resources: {
    en: { translations: { ...en } },
    ar: { translations: { ...ar } },
  },
  fallbackLng: "en",
  keySeparator: false,
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: { escapeValue: false },
  react: { useSuspense: false },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <StoreContext.Provider value={mobxStore}>
            <GetUserData>
              <LazyApp />
            </GetUserData>
          </StoreContext.Provider>
        </Layout>
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
