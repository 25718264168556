import { createContext, useContext } from 'react';
import AdminStore from './AdminStore';
import DataStore from './DataStore';
import MasterDataStore from './MasterDataStore';
import AccountStore from './AccountStore';
import ReportStore from './ReportStore';
import UserBIStore from './UserBIStore';
import EquipmentBIStore from './EquipmentBIStore';
import ExploreBIStore from './ExploreBIStore';
import ImportDataStore from './ImportDataStore';
import CustomerReportStore from './CustomerReportStore';
import TemplateStore from './TemplateStore';

export interface IStore {
  adminStore: AdminStore;
  dataStore: DataStore;
  masterDataStore: MasterDataStore;
  accountStore: AccountStore;
  reportStore: ReportStore;
  userBIStore: UserBIStore;
  equipmentBIStore: EquipmentBIStore;
  exploreBIStore: ExploreBIStore;
  importDataStore: ImportDataStore;
  customerReportStore: CustomerReportStore;
  templateStore: TemplateStore;
}
export const store: IStore = {
  adminStore: new AdminStore(),
  dataStore: new DataStore(),
  masterDataStore: new MasterDataStore(),
  accountStore: new AccountStore(),
  reportStore: new ReportStore(),
  userBIStore: new UserBIStore(),
  equipmentBIStore: new EquipmentBIStore(),
  exploreBIStore: new ExploreBIStore(),
  importDataStore: new ImportDataStore(),
  customerReportStore: new CustomerReportStore(),
  templateStore: new TemplateStore()
};
export const StoreContext = createContext(store);
export const useStore = () => {
  return useContext(StoreContext);
};
