import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getTotalEquipmentsEBI = (
  payload: EquipmentBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/EquipmentBI/totalequipments/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTotalEquipmentTypesEBI = (
  payload: EquipmentBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/EquipmentBI/totalequipmenttypes/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTotalEquipmentInAlarmsEBI = (
  payload: EquipmentBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/EquipmentBI/totalequipmentinalarms/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getHealthIndicatorPercentageEBI = (
  payload: EquipmentBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/EquipmentBI/healthindicatorpercentage/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getEquipmentByTypesEBI = (
  payload: EquipmentBIFilters
): Promise<APIResponse<EquipmentsByTypes[]>> =>
  request
    .post(`${apiURL}/EquipmentBI/getequipmentbytypes/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getHealthIndicatorPerDayEBI = (
  payload: EquipmentBIFilters
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/EquipmentBI/healthindicatorperday/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getEquipmentsStatusByTypes = (
  payload: EquipmentsByStatusPayload
): Promise<APIResponse<EquipmentsByStatusResponse[]>> =>
  request
    .post(`${apiURL}/EquipmentBI/equipmentsstatusbytype/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));