import React from "react";
import * as Icon from "react-feather";
import { EnumAppRights } from "utility/Common";
import assetImage from "../assets/img/asset.png";
import equipmentImage from "../assets/img/Equipment.png";
import teams from "../assets/img/navigation-icons/teams.svg";
import branch from "../assets/img/navigation-icons/branch.svg";
import section from "../assets/img/navigation-icons/depts.svg";
import subsection from "../assets/img/navigation-icons/lines.svg";
import rolesRight from "../assets/img/navigation-icons/roles.svg";
import shifts from "../assets/img/navigation-icons/shifts.svg";
import userBi from "../assets/img/navigation-icons/user-bi.svg";
import equipmentBi from "../assets/img/navigation-icons/equipment-bi.svg";
import exploreBi from "../assets/img/navigation-icons/explore-bi.svg";
import reportsBi from "../assets/img/navigation-icons/reports-bi.svg";
import Edocs from "../assets/img/navigation-icons/e-docs.svg";
import { useTranslation } from "react-i18next";

export const horizontalMenuConfig = (t): any[] => {
  return [
    {
      id: "home",
      title: t("mainNav.home"),
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["productOwner", "customer"],
      navLink: "/",
      rightId: EnumAppRights.Dashboard,
    },
    {
      id: "bi",
      title: t("mainNav.BI"),
      type: "dropdown",
      icon: <img src={reportsBi} alt="BI" width={20} height={20} />,
      customAccess: ["customer"],
      children: [
        {
          id: "ReportsBI",
          title: t("mainNav.BI.reportsBI"),
          type: "item",
          icon: <img src={reportsBi} alt="Reports BI" width={20} height={20} />,
          customAccess: ["customer"],
          navLink: "/reportsbi",
          rightId: EnumAppRights.Dashboard,
        },
        {
          id: "UserBI",
          title: t("mainNav.BI.userBI"),
          type: "item",
          icon: <img src={userBi} alt="User BI" width={20} height={20} />,
          customAccess: ["customer"],
          navLink: "/userbi",
          rightId: EnumAppRights.UserBI,
        },
        {
          id: "EquipmentBi",
          title: t("mainNav.BI.equipmentBI"),
          type: "item",
          icon: (
            <img src={equipmentBi} alt="Equipment BI" width={20} height={20} />
          ),
          customAccess: ["customer"],
          navLink: "/equipmentbi",
          rightId: EnumAppRights.EquipmentBI,
        },
        {
          id: "ExploreBi",
          title: t("mainNav.BI.exploreBI"),
          type: "item",
          icon: <img src={exploreBi} alt="Explore BI" width={20} height={20} />,
          customAccess: ["customer"],
          navLink: "/explorebi",
          rightId: EnumAppRights.ExploreBI,
        },
      ],
    },
    {
      id: "packages",
      title: t("mainNav.package"),
      type: "item",
      icon: <Icon.File size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["productOwner"],
      navLink: "/packages",
    },
    {
      id: "customers",
      title: t("mainNav.customer"),
      type: "item",
      icon: <Icon.File size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["productOwner"],
      navLink: "/customers",
    },
    {
      id: "subscriptions",
      title: t("mainNav.subscriptions"),
      type: "item",
      icon: <Icon.File size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["productOwner"],
      navLink: "/subscriptions",
    },
    {
      id: "alarmequipments",
      title: t("mainNav.alarmedEquipments"),
      type: "item",
      icon: <Icon.AlertTriangle size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["customer"],
      navLink: "/alarmequipments",
      rightId: EnumAppRights.AlarmedEquipmentsManagement,
    },
    {
      id: "FixRequest",
      title: t("mainNav.fixRequest"),
      type: "item",
      icon: <Icon.AlertOctagon size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["customer"],
      navLink: "/fixrequest",
      rightId: EnumAppRights.FixRequestsManagement,
    },
    {
      id: "scheduledroutes",
      title: t("mainNav.scheduledRoutes"),
      type: "item",
      icon: <Icon.CheckSquare size={20} />,
      permissions: ["admin", "editor"],
      customAccess: ["customer"],
      navLink: "/scheduledroutes",
      rightId: EnumAppRights.ScheduledRoutesManagement,
    },
    {
      id: "teams",
      title: t("mainNav.teams"),
      type: "item",
      icon: <img src={teams} alt="asset" />,
      customAccess: ["customer"],
      navLink: "/teams",
    },
    {
      id: "reports",
      title: t("mainNav.reports"),
      type: "dropdown",
      icon: <Icon.Settings size={20} />,
      customAccess: ["customer"],
      children: [
        {
          id: "ActionStatus",
          title: t("mainNav.reports.actionStatus"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/ActionStatus",
          rightId: EnumAppRights.DailyReport,
        },
        {
          id: "Daily Report",
          title: t("mainNav.reports.dailyReport"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/dailyreport",
          rightId: EnumAppRights.DailyReport,
        },
        {
          id: "Ip Type Anomaly",
          title: t("mainNav.reports.anomalyInsights"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/AnomalyInsights",
          rightId: EnumAppRights.IpTypeAnomalyReport,
        },
        {
          id: "EquipmentHealthSurvey",
          title: t("mainNav.reports.equipmentHealthSurvey"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/EquipmentHealthSurvey",
          rightId: EnumAppRights.EquipmentHealthReport,
        },
        {
          id: "EquipmentInsight",
          title: t("mainNav.reports.equipmentInsights"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/EquipmentInsights",
          rightId: EnumAppRights.LastInspectionIpStatusReport,
        },
        {
          id: "IpUtilization",
          title: t("mainNav.reports.IpUtilization"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/iputilization",
          rightId: EnumAppRights.IpUutilization,
        },
        {
          id: "AlarmedEquipmentHistory",
          title: t("mainNav.reports.alarmedEquipmentHistory"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/alarmedequipmenthistory",
          rightId: EnumAppRights.AlarmedEquipmentsManagement,
        },
        {
          id: "routeschedulesummary",
          title: t("mainNav.reports.routeScheduleSummary"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["customer"],
          navLink: "/routeschedulesummary",
          rightId: EnumAppRights.RouteScheduleSummary,
        },
      ],
    },
    {
      id: "configure",
      title: t("mainNav.configure"),
      type: "dropdown",
      icon: <Icon.Settings size={20} />,
      customAccess: ["productOwner", "customer"],
      children: [
        {
          id: "users",
          title: t("mainNav.configure.user"),
          type: "item",
          icon: <Icon.Users size={20} />,
          customAccess: ["productOwner", "customer"],
          navLink: "/users",
        },
        {
          id: "rolesrights",
          title: t("mainNav.configure.roles&Rights"),
          type: "item",
          icon: <img src={rolesRight} alt="Roles & Rights" />,
          customAccess: ["customer"],
          navLink: "/rolesrights",
          rightId: EnumAppRights.RolesRightsManagement,
        },
        {
          id: "branches",
          title: t("mainNav.configure.branch"),
          type: "item",
          icon: <img src={branch} alt="branch" width={20} height={20} />,
          customAccess: ["customer"],
          navLink: "/branches",
          rightId: EnumAppRights.BranchManagement,
        },
        {
          id: "sections",
          title: t("mainNav.configure.section"),
          type: "item",
          icon: <img src={section} alt="section" />,
          customAccess: ["customer"],
          navLink: "/sections",
          rightId: EnumAppRights.SectionsManagement,
        },
        {
          id: "subsections",
          title: t("mainNav.configure.subSection"),
          type: "item",
          icon: <img src={subsection} alt="Sub Section" />,
          customAccess: ["customer"],
          navLink: "/subsections",
          rightId: EnumAppRights.SubSectionsManagement,
        },
        {
          id: "assets",
          title: t("mainNav.configure.Asset"),
          type: "item",
          icon: (
            <img src={equipmentImage} alt="asset" style={{ height: "20px" }} />
          ),
          customAccess: ["customer"],
          navLink: "/assets",
          rightId: EnumAppRights.AssetManagement,
        },
        {
          id: "equipment",
          title: t("mainNav.configure.equipment"),
          type: "item",
          icon: <img src={assetImage} alt="asset" width={18} height={20} />,
          customAccess: ["customer"],
          navLink: "/equipments",
          rightId: EnumAppRights.EquipmentManagement,
        },
        {
          id: "inspectionpoint",
          title: t("mainNav.configure.InspectionPoint"),
          type: "item",
          icon: <Icon.CheckCircle size={20} />,
          customAccess: ["customer"],
          navLink: "/inspectionpoints",
          rightId: EnumAppRights.InspectionPointManagement,
        },
        {
          id: "routes",
          title: t("mainNav.configure.routes"),
          type: "item",
          icon: <Icon.Share2 size={20} />,
          customAccess: ["customer"],
          navLink: "/routes",
          rightId: EnumAppRights.RoutesManagement,
        },
        {
          id: "edocs",
          title: t("mainNav.configure.eDocs"),
          type: "item",
          icon: <img src={Edocs} alt="E Docs" />,
          customAccess: ["productOwner", "customer"],
          navLink: "/edocs",
          rightId: EnumAppRights.EDocsManagement,
        },
        {
          id: "shifts",
          title: t("mainNav.configure.shifts"),
          type: "item",
          icon: <img src={shifts} alt="shifts" />,
          customAccess: ["customer"],
          navLink: "/shifts",
          rightId: EnumAppRights.ShiftsManagment,
        },
        {
          id: "templates",
          title: t("mainNav.configure.templates"),
          type: "item",
          icon: <Icon.BarChart size={20} />,
          customAccess: ["productOwner", "customer"],
          navLink: "/templates",
          rightId: EnumAppRights.TemplateManagement,
        },
        {
          id: "templateRecipe",
          title: t("mainNav.configure.TemplateRecipe"),
          type: "item",
          icon: <Icon.FilePlus size={20} />,
          customAccess: ["productOwner", "customer"],
          navLink: "/recipies",
          rightId: EnumAppRights.BranchManagement,
        },
      ],
    },
  ];
};
