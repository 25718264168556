import { observable, flow} from 'mobx';
import * as api from "../utility/dm.api";

class ImportDataStore {

  @observable errorMessage: string | null = null;

  @observable importingEquipments: boolean = false;
  @observable importingEquipmentsResponse: APIResponse<boolean> | null = null;
  importEquipments = flow(function* (this: ImportDataStore, payload:  FormData) {
    this.importingEquipments = true;
    this.errorMessage = null;
    this.importingEquipmentsResponse = null;
    try {
      this.importingEquipmentsResponse = yield api.importEquipments(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.importingEquipments = false;
    }
  }).bind(this);


  @observable importingAssets: boolean = false;
  @observable importingAssetsResponse: APIResponse<boolean> | null = null;
  importAssets = flow(function* (this: ImportDataStore, payload:  FormData) {
    this.importingAssets = true;
    this.errorMessage = null;
    this.importingAssetsResponse = null;
    try {
      this.importingAssetsResponse = yield api.importAssets(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.importingAssets = false;
    }
  }).bind(this);


  @observable importingIps: boolean = false;
  @observable importingIpsResponse: APIResponse<boolean> | null = null;
  importIps = flow(function* (this: ImportDataStore, payload:  FormData) {
    this.importingIps = true;
    this.errorMessage = null;
    this.importingIpsResponse = null;
    try {
      this.importingIpsResponse = yield api.importIPs(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.importingIps = false;
    }
  }).bind(this);

}

export default ImportDataStore;
