import { observable, flow, action } from "mobx";
import * as api from "../utility/dm.api";
import { UploadMediaTypes, initPaginateListResponseGlobal, isGUID } from "utility/Common";

export default class AdminStore {
  @observable loading: DataLoading = {
    listLoading: false,
    saveDataLoading: false,
    deleteLoading: false,
    itemLoading: false,
  };
  @observable errorMessage: string | null = null;
  @observable CustomerListData: PaginatedListResponse<CustomerListResponse[]> = initPaginateListResponseGlobal<CustomerListResponse[]>([]);
  @observable saveDataResponse: APIResponse<boolean> | null = null;
  @observable deleteDataResponse: APIResponse<boolean> | null = null;
  @observable customerData: Customer | null = null;

  @action.bound
  clearDeleteDataResponse() {
    this.deleteDataResponse = null;
  }

  getCustomers = flow(function* (this: AdminStore, payload: PaginatedListPayload) {
    this.loading = {...this.loading, listLoading: true};
    this.errorMessage = null;
    this.saveDataResponse = null;
    this.deleteDataResponse = null;
    this.CustomerListData = initPaginateListResponseGlobal<CustomerListResponse[]>([]);
    try {
      const response: APIResponse<PaginatedListResponse<CustomerListResponse[]>> = yield api.getCustomers(payload);
      this.CustomerListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, listLoading: false};
    }
  }).bind(this);

  getCustomer = flow(function* (this: AdminStore, ID: string) {
    this.loading = {...this.loading, itemLoading: true};
    this.errorMessage = null;
    this.saveDataResponse = null;
    this.customerData = null;
    try {
      const response: APIResponse<SingleCustomerResponse> = yield api.getCustomer(ID);
      this.customerData = {
        Id : response.data.id,
        Name : response.data.name,
        LogoPath : response.data.logoPath,
      } as Customer;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, itemLoading: false};
    }
  }).bind(this);

  saveCustomer = flow(function* (this: AdminStore, payload:  CustomerPayload) {
    this.loading = {...this.loading, saveDataLoading: true};
    this.errorMessage = null;
    this.saveDataResponse = null;
    try {
      let imageUrl: any = null;
      if (payload.imageMediaId.name) {
        const formData = new FormData();
        const file: any = payload.imageMediaId;
        formData.append(
          "mediaFile",
          file,
          file.name
        );
        imageUrl = yield api.uploadMedia(formData, UploadMediaTypes.Image);
      }
      const customerProfile = isGUID(payload.imageMediaId) || imageUrl.data ? {
        ...payload,
        imageMediaId: imageUrl ? imageUrl.data : payload.imageMediaId
      } : {
        ...payload,
        imageMediaId: null
      }
      this.saveDataResponse = yield api.saveCustomer(customerProfile);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, saveDataLoading: false};
    }
  }).bind(this);
  
  deleteCustomer = flow(function* (this: AdminStore, ID: string) {
    this.loading = {...this.loading, deleteLoading: true};

    this.errorMessage = null;
    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteCustomer(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {...this.loading, deleteLoading: false};
    }
  }).bind(this);

}
