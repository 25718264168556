import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

const getReportURL = (url: string): string => {
  return url;
};

export const getassertcount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/GetAssertCount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getusercount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/getusercount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getequipmentcount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/getequipmentcount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getipcount = (
 payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/getipcount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getscheduledroutecount = (
  startdate: string,
  endDate: string
): Promise<APIResponse<number>> =>
  request
    .get(`${apiURL}/Report/getscheduledroutecount/${startdate}/${endDate}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getequipmenttypecount = (
  startdate: string,
  endDate: string
): Promise<APIResponse<number>> =>
  request
    .get(`${apiURL}/Report/getequipmenttypecount/${startdate}/${endDate}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getequipmentalarmcount = (
  startdate: string,
  endDate: string
): Promise<APIResponse<number>> =>
  request
    .get(`${apiURL}/Report/getequipmentalarmcount/${startdate}/${endDate}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getmttr = (
  payload: MasterDashboardFilter
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/Report/getmttr`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getmtba = (
  payload: MasterDashboardFilter
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/Report/getmtba`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalroutecount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/gettotalroutecount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalroutebystatuspersentage = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(
      `${apiURL}/Report/gettotalroutebystatuspersentage`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalroutechartcount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<ReportStartDateTotal[]>> =>
  request
    .post(
      `${apiURL}/Report/gettotalroutechartcount`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalregisterequipmentcount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(
      `${apiURL}/Report/gettotalregisterequipmentcount`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalregisterequipmentinalarmstatecount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(
      `${apiURL}/Report/gettotalregisterequipmentinalarmstatecount`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalequipmentbreakdownsavedcount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(
      `${apiURL}/Report/gettotalequipmentbreakdownsavedcount`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalequipmentavrageresponsetime = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(
      `${apiURL}/Report/gettotalequipmentavrageresponsetime`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalequipmentcompliance = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/gettotalequipmentcompliance`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const gettotalcriticaltycount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/gettotalcriticaltycount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const criticaltycount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/Report/criticaltycount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const workorderscount = (
  payload: MasterDashboardFilter
): Promise<APIResponse<ReportXY[]>> =>
  request
    .post(`${apiURL}/Report/workorderscount`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const alarmtrendchart = (
payload: MasterDashboardFilter
): Promise<APIResponse<ReportStartDateTotal[]>> =>
  request
    .post(
      `${apiURL}/Report/alarmtrendchart`, payload
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const topbadactors = (
payload: MasterDashboardFilter
): Promise<APIResponse<ReportBadActor>> =>
  request
    .post(`${apiURL}/Report/topbadactors`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));
