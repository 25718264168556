import { filter } from 'ramda';
import { observable, flow, computed } from 'mobx';
import { dummyData } from '../utility/dummydata';
import moment from 'moment';
import * as api from "../utility/dm.api";
import { convertDateTimeToUtcDate } from 'utility/Common';


export default class UserBIStore {

  @observable errorMessage: string | null = null;

  // @observable userAverageInspectionCount: number = 0;
  // @observable userAverageInspectionCountLoading: boolean = false;
  // getUserAverageInspectionCount = flow(function* (this: UserBIStore, filters: UserBIFilters) {
  //   this.userAverageInspectionCountLoading = true;
  //   
  //   try {
  //     const response: APIResponse<number> = yield api.avginspectionperdaybyusercountUBI(filters);
  //     this.userAverageInspectionCount = response.data;
  //   } catch (error) {
  //     
  //   } finally {
  //     this.userAverageInspectionCountLoading = false;
  //   }
  // }).bind(this);

  @observable userAverageInspectionPerDay: ReportAverageGraph = { average: 0, graph: [] };
  @observable userAverageInspectionPerDayLoading: boolean = false;
  getUserAverageInspectionPerDay = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.userAverageInspectionPerDayLoading = true;
    this.userAverageInspectionPerDay = { average: 0, graph: [] };
    
    try {
      const response: APIResponse<ReportAverageGraph> = yield api.inspectionperdaybyuserUBI(filters);
      this.userAverageInspectionPerDay = response.data;
    } catch (error) {
      
    } finally {
      this.userAverageInspectionPerDayLoading = false;
    }
  }).bind(this);
  @computed
  get userAverageInspectionPerDayComputed() {
    return this.userAverageInspectionPerDay.graph.map((item: ReportXY) => ([moment(item.x).valueOf(), item.y]))
  }

  @observable MTTR: ReportXY[] = [];
  @observable MTTRLoading: boolean = false;
  getMTTR = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.MTTRLoading = true;
    this.MTTR = [];
    
    try {
      const response: APIResponse<ReportXY[]> = yield api.getMTTRUBI(filters);
      this.MTTR = response.data;
    } catch (error) {
      
    } finally {
      this.MTTRLoading = false;
    }
  }).bind(this);
  @computed
  get MTTRComputed() {
    return this.MTTR.map((item: ReportXY) => ([moment(item.x).valueOf(), item.y]))
  }

  @observable MTTRCount: number = 0;
  @observable MTTRCountLoading: boolean = false;
  getMTTRCount = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.MTTRCountLoading = true;
    this.MTTRCount = 0;
    
    try {
      const response: APIResponse<number> = yield api.getMTTRCountUBI(filters);
      this.MTTRCount = response.data;
    } catch (error) {
      
    } finally {
      this.MTTRCountLoading = false;
    }
  }).bind(this);


  @observable userTopSkipReasons: ReportXY[] = [];
  @observable getUserTopSkipReasonsLoading: boolean = false;
  getUserTopSkipReasons = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserTopSkipReasonsLoading = true;
    this.userTopSkipReasons = [];
    
    try {
      const response: APIResponse<ReportXY[]> = yield api.getTopSkipReasonsUBI(filters);
      this.userTopSkipReasons = response.data;
    } catch (error) {
      
    } finally {
      this.getUserTopSkipReasonsLoading = false;
    }
  }).bind(this);



  @observable userRouteCount: number = 0;
  @observable getUserRouteCountLoading: boolean = false;
  getUserRouteCount = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserRouteCountLoading = true;
    this.userRouteCount = 0;
    
    try {
      const response: APIResponse<number> = yield api.gettotalroutecountUBI(filters);
      this.userRouteCount = response.data;
    } catch (error) {
      
    } finally {
      this.getUserRouteCountLoading = false;
    }
  }).bind(this);


  @observable userRoutePercentageInProgress: number = 0;
  @observable getUserRoutePercentageInProgressLoading: boolean = false;
  getUserRoutePercentageInProgress = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserRoutePercentageInProgressLoading = true;
    this.userRoutePercentageInProgress = 0;
    
    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentageUBI({...filters, statusId: '5F9CBBF4-F9AE-4E4A-AA59-0A2F5C4FD26F'});
      this.userRoutePercentageInProgress = response.data;
    } catch (error) {
      
    } finally {
      this.getUserRoutePercentageInProgressLoading = false;
    }
  }).bind(this);

  @observable userRoutePercentageSkipped:  number = 0;
  @observable getUserRoutePercentageSkippedLoading: boolean = false;
  getUserRoutePercentageSkipped = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserRoutePercentageSkippedLoading = true;
    this.userRoutePercentageSkipped = 0;
    
    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentageUBI({...filters, statusId: '4F6614E2-47B6-497A-94EA-1D77B01D811C'});
      this.userRoutePercentageSkipped = response.data;
    } catch (error) {
      
    } finally {
      this.getUserRoutePercentageSkippedLoading = false;
    }
  }).bind(this);

  @observable userRoutePercentageMissed:  number = 0;
  @observable getUserRoutePercentageMissedLoading: boolean = false;
  getUserRoutePercentageMissed = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserRoutePercentageMissedLoading = true;
    
      this.userRoutePercentageMissed = 0;
      try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentageUBI({...filters, statusId: 'FCA7ED1B-1263-4C50-BCD7-56F725DD78FF'});
      this.userRoutePercentageMissed = response.data;
    } catch (error) {
      
    } finally {
      this.getUserRoutePercentageMissedLoading = false;
    }
  }).bind(this);

  @observable userRoutePercentageCompleted:  number = 0;
  @observable getUserRoutePercentageCompletedLoading: boolean = false;
  getUserRoutePercentageCompleted = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserRoutePercentageCompletedLoading = true;
    this.userRoutePercentageCompleted = 0;
    
    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentageUBI({...filters, statusId: '0F3134BE-DB07-4444-ABB2-878853FC4D48'});
      this.userRoutePercentageCompleted = response.data;
    } catch (error) {
      
    } finally {
      this.getUserRoutePercentageCompletedLoading = false;
    }
  }).bind(this);

  @observable userRoutePercentagePending:  number = 0;
  @observable getUserRoutePercentagePendingLoading: boolean = false;
  getUserRoutePercentagePending = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserRoutePercentagePendingLoading = true;
    this.userRoutePercentagePending = 0;
    
    try {
      const response: APIResponse<number> = yield api.gettotalroutebystatuspersentageUBI({...filters, statusId: 'B5855293-7BF1-474A-8636-B2344E8B3995'});
      this.userRoutePercentagePending = response.data;
    } catch (error) {
      
    } finally {
      this.getUserRoutePercentagePendingLoading = false;
    }
  }).bind(this);


  @observable userTotalRouteChartCount: ReportXY[] = [];
  @observable getUserTotalRouteChartCountLoading: boolean = false;
  getUserTotalRouteChartCount = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserTotalRouteChartCountLoading = true;
    this.userTotalRouteChartCount = [];
    
    try {
      const response: APIResponse<ReportXY[]> = yield api.gettotalroutechartcountUBI(filters);
      this.userTotalRouteChartCount = response.data;

    } catch (error) {
      
    } finally {
      this.getUserTotalRouteChartCountLoading = false;
    }
  }).bind(this);
  @computed
  get userTotalRouteChartCountComputed() {
    // return this.userTotalRouteChartCount.map(x=> x.y);
    return this.userTotalRouteChartCount.map(x=> ([moment(x.x).valueOf(), x.y]));

  }


  @observable userComplianceTrendChart: ReportXY[] = [];
  @observable getUserComplianceTrendChartLoading: boolean = false;
  getUserComplianceTrendChart = flow(function* (this: UserBIStore, filters: UserBIFilters) {
    this.getUserComplianceTrendChartLoading = true;
    this.userComplianceTrendChart = [];
    
    try {
      const response: APIResponse<ReportXY[]> = yield api.gettotalequipmentcomplianceUBI(filters);
      this.userComplianceTrendChart = response.data;
    } catch (error) {
      
    } finally {
      this.getUserComplianceTrendChartLoading = false;
    }
  }).bind(this);
  @computed
  get userComplianceTrendChartComputed() {
    return this.userComplianceTrendChart.map((x: ReportXY)=> ([moment(x.x).valueOf(), x.y]))
  }
}
