import { observable, action, flow } from "mobx";
import { initPaginateListResponseGlobal } from "utility/Common";
import * as api from "../utility/dm.api";

export default class TemplateStore {
  @observable errorMessage: string | null = null;

  @observable loading: { [key: string]: DataLoading } = {
    templates: { ...initLoading },
    templateProperties: { ...initLoading },
    templateRecipe: { ...initLoading },
    recipeExecution: {...initLoading}
  };

  @observable saveDataResponse: APIResponse<boolean> | null = null;
  @observable deleteDataResponse: APIResponse<boolean> | null = null;

  @action.bound
  clearDeleteDataResponse() {
    this.deleteDataResponse = null;
  }

  @action.bound
  clearSaveDataResponse() {
    this.saveDataResponse = null;
  }

  @observable TemplateListData: PaginatedListResponse<TemplateListResponse[]> =
    initPaginateListResponseGlobal<TemplateListResponse[]>([]);
  getTemplates = flow(function* (
    this: TemplateStore,
    payload: PaginatedFiltersListPayload
  ) {
    this.loading = {
      ...this.loading,
      templates: { ...this.loading.templates, listLoading: true },
    };
    this.TemplateListData = initPaginateListResponseGlobal<
      TemplateListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<TemplateListResponse[]>
      > = yield api.getTemplates(payload);
      this.TemplateListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        templates: { ...this.loading.templates, listLoading: false },
      };
    }
  }).bind(this);

  deleteTemplate = flow(function* (this: TemplateStore, ID: string, recursiveDelete: boolean) {
    this.loading = {
      ...this.loading,
      templates: { ...this.loading.templates, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteTemplate(ID, recursiveDelete);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        templates: { ...this.loading.templates, deleteLoading: false },
      };
    }
  }).bind(this);

  saveTemplate = flow(function* (this: TemplateStore, payload: TemplatesPayload) {
    this.loading = {
      ...this.loading,
      templates: { ...this.loading.templates, saveDataLoading: true },
    };
    
    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveTemplate(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        templates: { ...this.loading.templates, saveDataLoading: false },
      };
    }
    }).bind(this);

  @observable templateData: TemplatesPayload | null = null;

  getTemplate = flow(function* (this: TemplateStore, ID: string) {
    this.loading = {
      ...this.loading,
      templates: { ...this.loading.templates, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.templateData = null;
    try {
      const response: APIResponse<TemplatesPayload> = yield api.getTemplate(ID);
      this.templateData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        templates: { ...this.loading.templates, itemLoading: false },
      };
    }
  }).bind(this);

  @observable TemplatePropertiesListData: TemplatePropertiesListResponse[] = <
    TemplatePropertiesListResponse[]
  >[];
  getTemplateProperties = flow(function* (
    this: TemplateStore,
    templateTypeId: string
  ) {
    this.loading = {
      ...this.loading,
      templateProperties: {
        ...this.loading.templateProperties,
        listLoading: true,
      },
    };
    this.TemplatePropertiesListData = <TemplatePropertiesListResponse[]>[];
    try {
      const response: APIResponse<TemplatePropertiesListResponse[]> = yield api.getTemplateTypes(templateTypeId);
      this.TemplatePropertiesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        templateProperties: {
          ...this.loading.templateProperties,
          deleteLoading: false,
        },
      };
    }
  }).bind(this);

@observable TemplateRecipeListData: PaginatedListResponse<TemplateRecipeListResponse[]> =
  initPaginateListResponseGlobal<TemplateRecipeListResponse[]>([]);
getTemplateRecipes = flow(function* (
  this: TemplateStore,
  payload: PaginatedFiltersListPayload
) {
  this.loading = {
    ...this.loading,
    templateRecipe: { ...this.loading.templateRecipe, listLoading: true },
  };
  this.TemplateRecipeListData = initPaginateListResponseGlobal<
  TemplateRecipeListResponse[]
  >([]);

  try {
    const response: APIResponse<
      PaginatedListResponse<TemplateRecipeListResponse[]>
    > = yield api.getTemplateRecipes(payload);
    this.TemplateRecipeListData = response.data;
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      templateRecipe: { ...this.loading.templateRecipe, listLoading: false },
    };
  }
}).bind(this);

deletetemplateRecipe = flow(function* (this: TemplateStore, ID: string, recursiveDelete: boolean) {
  this.loading = {
    ...this.loading,
    templateRecipe: { ...this.loading.templateRecipe, deleteLoading: true },
  };

  this.deleteDataResponse = null;
  try {
    this.deleteDataResponse = yield api.deleteTemplateRecipe(ID, recursiveDelete);
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      templateRecipe: { ...this.loading.templateRecipe, deleteLoading: false },
    };
  }
}).bind(this);

saveTemplateRecipe = flow(function* (this: TemplateStore, payload: TemplateRecipeRequest) {
  this.loading = {
    ...this.loading,
    templateRecipe: { ...this.loading.templateRecipe, saveDataLoading: true },
  };
  
  this.saveDataResponse = null;
  try {
    this.saveDataResponse = yield api.saveTemplateRecipe(payload);
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      templateRecipe: { ...this.loading.templateRecipe, saveDataLoading: false },
    };
  }
  }).bind(this);

  executeTemplateRecipe = flow(function* (this: TemplateStore, payload: TemplateRecipeExecutionPayload) {
    this.loading = {
      ...this.loading,
      recipeExecution: { ...this.loading.recipeExecution, saveDataLoading: true },
    };
    
    this.saveDataResponse = null;
    try {
      let response = null;
      try {
        for (let i = 0; i < payload.executionCount; i++) {
          response = yield api.executeTemplateRecipe(payload);
        }
      } catch (error) {
        this.errorMessage = "Unable to load data";
      } finally {
        this.saveDataResponse = response;
        (response && response.data) && api.showSuccessNoti();
      }
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        recipeExecution: { ...this.loading.recipeExecution, saveDataLoading: false },
      };
    }
    }).bind(this);
}

const initLoading: DataLoading = {
  listLoading: false,
  saveDataLoading: false,
  deleteLoading: false,
  itemLoading: false,
};
