import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const exploreTotalInspectionCount = (
  payload: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/ExploreBI/totalinspections/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploreGetTotalAnomalyCount = (
  payload: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/ExploreBI/totalanomaly/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploreGetExploreAlarmTrendChart = (
  payload: ExploreBIFilters
): Promise<APIResponse<any[]>> =>
  request
    .post(`${apiURL}/ExploreBI/alarmtrendchart/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploreGetInspectionData = (
  payload: ExploreBiGetDataRequest
): Promise<APIResponse<any[]>> =>
  request
    .post(`${apiURL}/ExploreBI/getdata/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploregettotalregisterequipmentcount = (
  filters: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/ExploreBI/gettotalregisterequipmentcount/`, filters)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploregettotalregisterequipmentinalarmstatecount = (
  filters: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(
      `${apiURL}/ExploreBI/gettotalregisterequipmentinalarmstatecount/`,
      filters
    )
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploregettotalequipmentbreakdownsavedcount = (
  filters: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/ExploreBI/gettotalequipmentbreakdownsavedcount/`, filters)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploregettotalequipmentavrageresponsetime = (
  filters: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/ExploreBI/gettotalequipmentavrageresponsetime/`, filters)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploregettotalequipmentcompliance = (
  filters: ExploreBIFilters
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/ExploreBI/gettotalequipmentcompliance/`, filters)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exploretopbadactors = (
  filters: ExploreBIFilters
): Promise<APIResponse<any[]>> =>
  request
    .post(`${apiURL}/ExploreBI/topbadactors/`, filters)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));
