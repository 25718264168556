import { observable, flow, action } from "mobx";
import * as api from "../utility/dm.api";
import {
  initPaginateListResponseGlobal,
  convertDateTimeToUtcDate,
  cbmLogoSvg64,
  UploadMediaTypes,
  isGUID
} from "utility/Common";
import moment from "moment";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { clone } from "ramda";

export default class DataStore {
  @observable errorMessage: string | null = null;

  @observable loading: { [key: string]: DataLoading } = {
    packages: { ...initLoading },
    branch: { ...initLoading },
    section: { ...initLoading },
    subSection: { ...initLoading },
    asset: { ...initLoading },
    shift: { ...initLoading },
    equipment: { ...initLoading },
    inspectionPoint: { ...initLoading },
    route: { ...initLoading },
    routeSchedule: { ...initLoading },
    workOrder: { ...initLoading },
    customerSubscription: { ...initLoading },
    scheduler: { ...initLoading },
    teams: {...initLoading},
    ipAnomalyCount: {...initLoading},
    documents: {...initLoading},
    equipmentTypes: {...initLoading}
  };
  @observable saveDataResponse: APIResponse<boolean> | null = null;
  @observable deleteDataResponse: APIResponse<boolean> | null = null;

  @action.bound
  clearDeleteDataResponse() {
    this.deleteDataResponse = null;
  }

  @action.bound
  clearSaveDataResponse() {
    this.saveDataResponse = null;
  }

  @observable PackageListData: PaginatedListResponse<PackagesListResponse[]> =
    initPaginateListResponseGlobal<PackagesListResponse[]>([]);
  @observable packageData: Package | null = null;

  getPackages = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, listLoading: true },
    };
    this.PackageListData = initPaginateListResponseGlobal<
      PackagesListResponse[]
    >([]);
    try {
      const response: APIResponse<
        PaginatedListResponse<PackagesListResponse[]>
      > = yield api.getPackages(payload);
      this.PackageListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, listLoading: false },
      };
    }
  }).bind(this);

  getPackage = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.packageData = null;
    try {
      const response: APIResponse<Package> = yield api.getPackage(ID);
      this.packageData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, itemLoading: false },
      };
    }
  }).bind(this);

  savePackage = flow(function* (this: DataStore, payload: PackagePayload) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.savePackage(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, saveDataLoading: false },
      };
    }
  }).bind(this);

  deletePackage = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deletePackage(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable BranchesListData: PaginatedListResponse<BranchListResponse[]> =
    initPaginateListResponseGlobal<BranchListResponse[]>([]);
  @observable branchData: Branch | null = null;

  getBranches = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, listLoading: true },
    };
    this.BranchesListData = initPaginateListResponseGlobal<
      BranchListResponse[]
    >([]);

    try {
      const response: APIResponse<PaginatedListResponse<BranchListResponse[]>> =
        yield api.getBranches(payload);
      this.BranchesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, listLoading: false },
      };
    }
  }).bind(this);

  getBranch = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.branchData = null;
    try {
      const response: APIResponse<Branch> = yield api.getBranch(ID);
      this.branchData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, itemLoading: false },
      };
    }
  }).bind(this);

  saveBranch = flow(function* (this: DataStore, payload: BranchPayload) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveBranch(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteBranch = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteBranch(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable SectionsListData: PaginatedListResponse<SectionListResponse[]> =
    initPaginateListResponseGlobal<SectionListResponse[]>([]);
  @observable sectionData: Section | null = null;

  getSections = flow(function* (
    this: DataStore,
    payload: PaginatedFiltersListPayload
  ) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, listLoading: true },
    };
    this.SectionsListData = initPaginateListResponseGlobal<
      SectionListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<SectionListResponse[]>
      > = yield api.getSections(payload);
      this.SectionsListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, listLoading: false },
      };
    }
  }).bind(this);

  getSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.sectionData = null;
    try {
      const response: APIResponse<Section> = yield api.getSection(ID);
      this.sectionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, itemLoading: false },
      };
    }
  }).bind(this);

  saveSection = flow(function* (this: DataStore, payload: SectionPayload) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveSection(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteSection(ID, false);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable subSectionListData: PaginatedListResponse<
    SubSectionListResponse[]
  > = initPaginateListResponseGlobal<SubSectionListResponse[]>([]);
  @observable subSectionData: SubSection | null = null;

  getSubSections = flow(function* (
    this: DataStore,
    payload: PaginatedFiltersListPayload
  ) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, listLoading: true },
    };
    this.subSectionListData = initPaginateListResponseGlobal<
      SubSectionListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<SubSectionListResponse[]>
      > = yield api.getSubSections(payload);
      this.subSectionListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, listLoading: false },
      };
    }
  }).bind(this);

  @observable exportSubSectionListDataLoading: boolean = false;
  getExportSubSections = flow(function* (this: DataStore) {
    this.exportSubSectionListDataLoading = true;

    try {
      yield api.exportSubSections();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportSubSectionListDataLoading = false;
    }
  }).bind(this);

  getSubSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.subSectionData = null;
    try {
      const response: APIResponse<SubSection> = yield api.getSubSection(ID);
      this.subSectionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, itemLoading: false },
      };
    }
  }).bind(this);

  saveSubSection = flow(function* (
    this: DataStore,
    payload: SubSectionPayload
  ) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveSubSection(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteSubSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteSubSection(ID, false);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable assetListData: PaginatedListResponse<AssetListResponse[]> =
    initPaginateListResponseGlobal<AssetListResponse[]>([]);
  @observable assetData: Asset | null = null;

  getAssets = flow(function* (this: DataStore, payload: PaginatedFiltersListPayload) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, listLoading: true },
    };
    this.assetListData = initPaginateListResponseGlobal<AssetListResponse[]>(
      []
    );

    try {
      const response: APIResponse<PaginatedListResponse<AssetListResponse[]>> =
        yield api.getAssets(payload);
      this.assetListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, listLoading: false },
      };
    }
  }).bind(this);

  @observable exportAssetListDataLoading: boolean = false;
  getExportAssets = flow(function* (this: DataStore) {
    this.exportAssetListDataLoading = true;

    try {
        yield api.exportAssets();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportAssetListDataLoading = false;
    }
  }).bind(this);

  getAsset = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.assetData = null;
    try {
      const response: APIResponse<Asset> = yield api.getAsset(ID);
      this.assetData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, itemLoading: false },
      };
    }
  }).bind(this);

  saveAsset = flow(function* (this: DataStore, payload: AssetPayload) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveAsset(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteAsset = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteAsset(ID, true);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable shiftListData: PaginatedListResponse<ShiftListResponse[]> =
    initPaginateListResponseGlobal<ShiftListResponse[]>([]);
  @observable shiftData: Shift | null = null;

  getShifts = flow(function* (this: DataStore, payload: PaginatedListPayload) {
    this.loading = {
      ...this.loading,
      shift: { ...this.loading.shift, listLoading: true },
    };
    this.shiftListData = initPaginateListResponseGlobal<ShiftListResponse[]>(
      []
    );

    try {
      const response: APIResponse<PaginatedListResponse<ShiftListResponse[]>> =
        yield api.getShifts(payload);
      this.shiftListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        shift: { ...this.loading.shift, listLoading: false },
      };
    }
  }).bind(this);

  getShift = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      shift: { ...this.loading.shift, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.shiftData = null;
    try {
      const response: APIResponse<Shift> = yield api.getShift(ID);
      this.shiftData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        shift: { ...this.loading.shift, itemLoading: false },
      };
    }
  }).bind(this);

  saveShift = flow(function* (this: DataStore, payload: ShiftPayload) {
    this.loading = {
      ...this.loading,
      shift: { ...this.loading.shift, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveShift(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        shift: { ...this.loading.shift, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteShift = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      shift: { ...this.loading.shift, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteShift(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        shift: { ...this.loading.shift, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable saveCustomerShiftsLoading: boolean = false;
  @observable saveCustomerShiftsResponse: boolean | null = null;
  saveCustomerShifts = flow(function* (this: DataStore, payload: string[]) {
    this.saveCustomerShiftsLoading = true;

    this.saveCustomerShiftsResponse = null;
    try {
      const response: any = yield api.saveCustomerShifts(payload);
      this.saveCustomerShiftsResponse = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.saveCustomerShiftsLoading = false;
    }
  }).bind(this);

  @observable equipmentListData: PaginatedListResponse<
    EquipmentListResponse[]
  > = initPaginateListResponseGlobal<EquipmentListResponse[]>([]);
  @observable equipmentData: Equipment | null = null;

  getEquipments = flow(function* (
    this: DataStore,
    payload: PaginatedFiltersListPayload
  ) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, listLoading: true },
    };
    this.equipmentListData = initPaginateListResponseGlobal<
      EquipmentListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<EquipmentListResponse[]>
      > = yield api.getEquipments(payload);
      this.equipmentListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, listLoading: false },
      };
    }
  }).bind(this);

  @action
  clearEquipmentsListData = () => {
    this.equipmentListData = initPaginateListResponseGlobal<
      EquipmentListResponse[]
    >([]);
  };

  @observable EquipmentQrSVGs: { svg: string; name: string }[] = [];
  @observable LoadingEquipmentQRCodeSVgs: boolean = false;
  getQREquipments = flow(function* (this: DataStore, searchQuery: string = '', equipmentListData: PaginatedListResponse<
  EquipmentListResponse[]
> = initPaginateListResponseGlobal<EquipmentListResponse[]>([])) {
    this.LoadingEquipmentQRCodeSVgs = true;
    try {
      this.EquipmentQrSVGs = [];
      const promises: Promise<any>[] = [];

      // Generate QR code via third party lib
      equipmentListData.data.forEach((equipment) => {
        const promise = api.getEquipmentQR(equipment);
        promises.push(promise);
      });

      const svgList: { svg: string; name: string }[] = [];
      // convert responses to SVGs and mantain a list
      yield Promise.all(promises).then((values) => {
       
        values.forEach((promiseValue) => {
          const wrapper: HTMLDivElement = document.createElement("div");
          wrapper.innerHTML = promiseValue.svgText;
          const qrCode: SVGSVGElement = wrapper.getElementsByTagName("svg")[0];

          const qrPath = qrCode.getElementsByTagName("g")[0];
          qrPath.setAttribute("transform", "translate(0, 325)");
          qrPath.setAttribute("width", "10px");


          const svgNS = "http://www.w3.org/2000/svg";
          const svgimg = document.createElementNS(svgNS, "image");
          svgimg.setAttributeNS(null, "height", "240");
          svgimg.setAttributeNS(null, "width", "100%");
          svgimg.setAttributeNS(
            "http://www.w3.org/1999/xlink",
            "href",
            cbmLogoSvg64
          );
          svgimg.setAttributeNS(null, "x", "0");
          svgimg.setAttributeNS(null, "y", "0");
          svgimg.setAttributeNS(null, "visibility", "visible");

          const titleText = document.createElementNS(svgNS, "text");
          titleText.setAttributeNS(null, "x", "50%");
          titleText.setAttributeNS(null, "y", "260");
          titleText.setAttributeNS(null, "font-size", "30");
          titleText.setAttributeNS(null, "font-weight", "bold");
          titleText.setAttributeNS(null, "font-family", "arial");
          titleText.setAttributeNS(null, "fill", "black");
          titleText.setAttributeNS(null, "alignment-baseline", "middle");
          titleText.setAttributeNS(null, "text-anchor", "middle");

          const titleNode = document.createTextNode(`TP Mechanisms`);
          titleText.appendChild(titleNode);

          const assetNameText = document.createElementNS(svgNS, "text");
          assetNameText.setAttributeNS(null, "x", "50%");
          assetNameText.setAttributeNS(null, "y", "287");
          assetNameText.setAttributeNS(null, "font-size", "20");
          assetNameText.setAttributeNS(null, "font-weight", "bold");
          assetNameText.setAttributeNS(null, "font-family", "arial");
          assetNameText.setAttributeNS(null, "fill", "black");
          assetNameText.setAttributeNS(null, "alignment-baseline", "middle");
          assetNameText.setAttributeNS(null, "text-anchor", "middle");

          const assetNameNode = document.createTextNode(
            `${promiseValue.equipment.assetName}`
          );
          assetNameText.appendChild(assetNameNode);

          const equipmentNameText = document.createElementNS(svgNS, "text");
          equipmentNameText.setAttributeNS(null, "x", "50%");
          equipmentNameText.setAttributeNS(null, "y", "308");
          equipmentNameText.setAttributeNS(null, "font-size", "20");
          equipmentNameText.setAttributeNS(null, "font-weight", "bold");
          equipmentNameText.setAttributeNS(null, "font-family", "arial");
          equipmentNameText.setAttributeNS(null, "fill", "black");
          equipmentNameText.setAttributeNS(
            null,
            "alignment-baseline",
            "middle"
          );
          equipmentNameText.setAttributeNS(null, "text-anchor", "middle");

          const equipmentNameTextNode = document.createTextNode(
            `${promiseValue.equipment.name}`
          );
          equipmentNameText.appendChild(equipmentNameTextNode);

          const equipmentCodeText = document.createElementNS(svgNS, "text");
          equipmentCodeText.setAttributeNS(null, "x", "50%");
          equipmentCodeText.setAttributeNS(null, "y", "577");
          equipmentCodeText.setAttributeNS(null, "font-size", "20");
          equipmentCodeText.setAttributeNS(null, "font-weight", "bold");
          equipmentCodeText.setAttributeNS(null, "font-family", "arial");
          equipmentCodeText.setAttributeNS(null, "fill", "black");
          equipmentCodeText.setAttributeNS(
            null,
            "alignment-baseline",
            "middle"
          );
          equipmentCodeText.setAttributeNS(null, "text-anchor", "middle");

          const equipmentCodeTextNode = document.createTextNode(
            `${promiseValue.equipment.code}`
          );
          equipmentCodeText.appendChild(equipmentCodeTextNode);

          // const svg = document.getElementById(equipmentID);
          qrCode?.setAttribute("height", "600");
          qrCode?.appendChild(assetNameText);
          qrCode?.appendChild(assetNameText);
          qrCode?.appendChild(equipmentNameText);
          qrCode?.appendChild(equipmentCodeText);
          qrCode?.appendChild(titleText);
          qrCode?.appendChild(svgimg);

          const serializedSVG: string = new XMLSerializer().serializeToString(
            qrCode
          );
          const base64Data: string = window.btoa(serializedSVG);

          svgList.push({ svg: base64Data, name: promiseValue.equipment.code });
          wrapper.remove();
        });

        this.EquipmentQrSVGs = svgList;
        // var zip = new JSZip();
        // svgList.forEach((svg, index) =>
        //   zip.file(`TPM-QR-${index}-${svg.name}.svg`, svg.svg, { base64: true })
        // );
        // zip.generateAsync({ type: "blob" }).then(function (content) {
        //   // Force down of the Zip file
        //   saveAs(content, `Equipment-QR-Codes_${moment().format("L")}.zip`);
        // });
      });
    }
    catch (error) {
      // this.errorMessage = "Unable to load data";
    }
    finally {
      this.LoadingEquipmentQRCodeSVgs = false;
    }
  }).bind(this);

  @observable loadingQREquipments: boolean = false;
  DownloadZipEquipmentQRCodes = flow(function* (this: DataStore) {
    this.loadingQREquipments = true;
    try {

      var zip = new JSZip();
        this.EquipmentQrSVGs.forEach((svg, index) =>
          zip.file(`TPM-QR-${index}-${svg.name}.svg`, svg.svg, { base64: true })
        );
        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, `Equipments-QR-Codes_${moment().format("L")}.zip`);
      });
      // });
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loadingQREquipments = false;
    }
  }).bind(this);

  @observable alarmEquipmentListData: PaginatedListResponse<
    AlarmedEquipmentListResponse[]
  > = initPaginateListResponseGlobal<AlarmedEquipmentListResponse[]>([]);
  @observable alarmEquipmentListDataLoading: boolean = false;
  getAlarmedEquipments = flow(function* (
    this: DataStore,
    payload: GetAlarmedEquipmentPayload
  ) {
    this.alarmEquipmentListDataLoading = true;
    this.alarmEquipmentListData = initPaginateListResponseGlobal<
      AlarmedEquipmentListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<AlarmedEquipmentListResponse[]>
      > = yield api.getAlarmedEquipments(payload);
      this.alarmEquipmentListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.alarmEquipmentListDataLoading = false;
    }
  }).bind(this);

  @observable alarmEquipmentDetail: {
    [key: string]: AlarmedEquipmentDetail[] | null;
  } = {};
  getAlarmedEquipmentsDetail = flow(function* (
    this: DataStore,
    payload: AlarmedEquipmentDetailPayload
  ) {
    this.alarmEquipmentDetail[`id-${payload.equipmentId}`] = null;
    try {
      const response: APIResponse<AlarmedEquipmentDetail[]> =
        yield api.getAlarmedEquipmentsDetail(payload);
      this.alarmEquipmentDetail[`id-${payload.equipmentId}`] = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    }
  }).bind(this);

  @observable exportEquipmentListDataLoading: boolean = false;
  getExportEquipments = flow(function* (this: DataStore) {
    this.exportEquipmentListDataLoading = true;

    try {
      yield api.exportEquipments();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportEquipmentListDataLoading = false;
    }
  }).bind(this);

  getEquipment = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.equipmentData = null;
    try {
      const response: APIResponse<Equipment> = yield api.getEquipment(ID);
      this.equipmentData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, itemLoading: false },
      };
    }
  }).bind(this);

  saveEquipment = flow(function* (this: DataStore, payload: EquipmentPayload) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveEquipment(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteEquipment = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteEquipment(ID, true);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable inspectionPointListData: PaginatedListResponse<
    InspectionPointListResponse[]
  > = initPaginateListResponseGlobal<InspectionPointListResponse[]>([]);
  @observable inspectionPointData: InspectionPoint | null = null;

  getInspectionPoints = flow(function* (
    this: DataStore,
    payload: PaginatedFiltersListPayload
  ) {
    this.loading = {
      ...this.loading,
      inspectionPoint: { ...this.loading.inspectionPoint, listLoading: true },
    };
    this.inspectionPointListData = initPaginateListResponseGlobal<
      InspectionPointListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<InspectionPointListResponse[]>
      > = yield api.getInspectionPoints(payload);
      this.inspectionPointListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        inspectionPoint: {
          ...this.loading.inspectionPoint,
          listLoading: false,
        },
      };
    }
  }).bind(this);

  @observable exportInspectionPointListDataLoading: boolean = false;
  getExportInspectionPoints = flow(function* (this: DataStore) {
    this.exportInspectionPointListDataLoading = true;

    try {
      yield api.exportInspectionPoints();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportInspectionPointListDataLoading = false;
    }
  }).bind(this);

  @observable equipmentsIpList: {
    [key: string]: PaginatedListResponse<InspectionPointListResponse[]>;
  } = {};
  @observable equipmentsIpListLoading: { [key: string]: boolean } = {};
  getInspectionPointsByEquipment = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload,
    equipmentID: string
  ) {
    this.equipmentsIpListLoading[equipmentID] = true;
    const tempList = clone(this.equipmentsIpList);
    delete tempList[equipmentID];
    this.equipmentsIpList = tempList;

    try {
      const response: APIResponse<
        PaginatedListResponse<InspectionPointListResponse[]>
      > = yield api.getEquipmentInspectionPoints(payload, equipmentID);
      this.equipmentsIpList[equipmentID] = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.equipmentsIpListLoading[equipmentID] = false;
    }
  }).bind(this);

  getInspectionPoint = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      inspectionPoint: { ...this.loading.inspectionPoint, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.inspectionPointData = null;
    try {
      const response: APIResponse<InspectionPoint> =
        yield api.getInspectionPoint(ID);
      this.inspectionPointData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        inspectionPoint: {
          ...this.loading.inspectionPoint,
          itemLoading: false,
        },
      };
    }
  }).bind(this);

  saveInspectionPoint = flow(function* (
    this: DataStore,
    payload: InspectionPoint
  ) {
    this.loading = {
      ...this.loading,
      inspectionPoint: {
        ...this.loading.inspectionPoint,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveInspectionPoint(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        inspectionPoint: {
          ...this.loading.inspectionPoint,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteInspectionPoint = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      inspectionPoint: { ...this.loading.inspectionPoint, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteInspectionPoint(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        inspectionPoint: {
          ...this.loading.inspectionPoint,
          deleteLoading: false,
        },
      };
    }
  }).bind(this);

  @observable ipAnomalyCountData: IpAnomalyCountResponse[] = [];
  getIpAnomalyCount = flow(function* (
    this: DataStore,
    equipmentID: string
  ) {

    this.loading = {
      ...this.loading,
      ipAnomalyCount: { ...this.loading.ipAnomalyCount, itemLoading: true },
    };

    try {
      const response: APIResponse<
        IpAnomalyCountResponse[]
      > = yield api.getIpAnomalyCount(equipmentID);
      this.ipAnomalyCountData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        ipAnomalyCount: { ...this.loading.ipAnomalyCount, itemLoading: false },
      };
    }
  }).bind(this);

  @observable routeListData: PaginatedListResponse<RouteListResponse[]> =
    initPaginateListResponseGlobal<RouteListResponse[]>([]);

  @observable routeData: Route | null = null;

  getRoutes = flow(function* (this: DataStore, payload: PaginatedFiltersListPayload) {
    this.loading = {
      ...this.loading,
      route: { ...this.loading.route, listLoading: true },
    };
    this.routeListData = initPaginateListResponseGlobal<RouteListResponse[]>(
      []
    );

    try {
      const response: APIResponse<PaginatedListResponse<RouteListResponse[]>> =
        yield api.getRoutes(payload);
      this.routeListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        route: { ...this.loading.route, listLoading: false },
      };
    }
  }).bind(this);

  getRoute = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      route: { ...this.loading.route, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.routeData = null;
    try {
      const response: APIResponse<Route> = yield api.getRoute(ID);
      this.routeData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        route: { ...this.loading.route, itemLoading: false },
      };
    }
  }).bind(this);

  saveRoute = flow(function* (this: DataStore, payload: Route) {
    this.loading = {
      ...this.loading,
      route: { ...this.loading.route, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveRoute(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        route: { ...this.loading.route, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteRoute = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      route: { ...this.loading.route, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteRoute(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        route: { ...this.loading.route, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable routeScheduleListData: PaginatedListResponse<
    RouteScheduleListData[]
  > = initPaginateListResponseGlobal<RouteScheduleListData[]>([]);

  getRouteSchedules = flow(function* (
    this: DataStore,
    payload: PaginatedListPayloadRS
  ) {
    this.loading = {
      ...this.loading,
      routeSchedule: { ...this.loading.routeSchedule, listLoading: true },
    };

    this.runSchedulerData = null;
    this.changeRouteScheduleAssignedToResponse = null;
    this.routeScheduleListData = initPaginateListResponseGlobal<
      RouteScheduleListData[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<RouteScheduleListData[]>
      > = yield api.getRouteSchedules({
        ...payload,
        startDate: convertDateTimeToUtcDate(payload.startDate),
        endDate: convertDateTimeToUtcDate(payload.endDate),
      });
      this.routeScheduleListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        routeSchedule: { ...this.loading.routeSchedule, listLoading: false },
      };
    }
  }).bind(this);

  @observable routeSchedule: { [key: string]: RouteScheduleListData } = {};
  @observable routeScheduleLoading: { [key: string]: boolean } = {};

  getRouteSchedule = flow(function* (this: DataStore, id: string) {
    if (this.routeSchedule[`key-${id}`]) {
      return;
    }
    this.routeScheduleLoading[`key-${id}`] = true;
    const tempList = this.routeSchedule;
    delete tempList[`key-${id}`];
    this.routeSchedule = tempList;

    try {
      const response: APIResponse<RouteScheduleListData> =
        yield api.getsinglerouteschedule(id);
      this.routeSchedule[`key-${id}`] = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.routeScheduleLoading[`key-${id}`] = false;
    }
  }).bind(this);

  @observable
  changeRouteScheduleAssignedToResponse: boolean | null = null;
  changeRouteScheduleAssignedTo = flow(function* (
    this: DataStore,
    payload: ChangeRouteAssignedToPayload
  ) {
    this.loading = {
      ...this.loading,
      routeSchedule: { ...this.loading.routeSchedule, itemLoading: true },
    };

    this.changeRouteScheduleAssignedToResponse = null;
    try {
      const response = yield api.changeScheduledRouteAssignee(payload);
      this.changeRouteScheduleAssignedToResponse = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        routeSchedule: { ...this.loading.routeSchedule, itemLoading: false },
      };
    }
  }).bind(this);

  @observable workOrderListData: PaginatedListResponse<
    WorkOrderListResponse[]
  > = initPaginateListResponseGlobal<WorkOrderListResponse[]>([]);

  @observable workOrderData: WorkOrder | null = null;

  getWorkOrders = flow(function* (
    this: DataStore,
    payload: PaginatedFiltersListPayload
  ) {
    this.loading = {
      ...this.loading,
      workOrder: { ...this.loading.workOrder, listLoading: true },
    };
    this.workOrderListData = initPaginateListResponseGlobal<
      WorkOrderListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<WorkOrderListResponse[]>
      > = yield api.getWorkOrdersWeb(payload);
      this.workOrderListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        workOrder: { ...this.loading.workOrder, listLoading: false },
      };
    }
  }).bind(this);

  getWorkOrder = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      workOrder: { ...this.loading.workOrder, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.workOrderData = null;
    try {
      const response: APIResponse<WorkOrder> = yield api.getWorkOrder(ID);
      this.workOrderData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        workOrder: { ...this.loading.workOrder, itemLoading: false },
      };
    }
  }).bind(this);

  saveWorkOrder = flow(async function* (this: DataStore, payload: WorkOrder) {
    this.loading = {
      ...this.loading,
      workOrder: { ...this.loading.workOrder, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      const workOrderMediaIds: WorkOrderMediaResponse[] = [];
      const mediaPromises: Promise<any>[] = [];
      for (const singleMedia of payload.media) {
        const formData = new FormData();
        if (singleMedia.id && !isGUID(singleMedia.id)) {
        const file: any = singleMedia.id;
          formData.append("mediaFile", file, file.name);
          mediaPromises.push(
            api.uploadMedia(formData, UploadMediaTypes.Image)
              .then((data) => workOrderMediaIds.push({
                id: data.data
              }))
              .catch((error) => {
                console.log(error);
              })
          );
        } else {
          if(singleMedia && singleMedia !=''){
            workOrderMediaIds.push(singleMedia);
          }
        }
      }
      await Promise.all(mediaPromises);
      this.saveDataResponse = await api.saveWorkOrder({
        ...payload,
        media: workOrderMediaIds ?? []
      });
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        workOrder: { ...this.loading.workOrder, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteWorkOrder = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      workOrder: { ...this.loading.workOrder, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteWorkOrder(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        workOrder: { ...this.loading.workOrder, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable customerSubscriptionListData: PaginatedListResponse<
    CustomerSubscriptionListResponse[]
  > = initPaginateListResponseGlobal<CustomerSubscriptionListResponse[]>([]);

  @observable customerSubscriptionData: CustomerSubscription | null = null;

  getCustomerSubscriptions = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        listLoading: true,
      },
    };
    this.customerSubscriptionListData = initPaginateListResponseGlobal<
      CustomerSubscriptionListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<CustomerSubscriptionListResponse[]>
      > = yield api.getCustomerSubscriptions(payload);
      this.customerSubscriptionListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          listLoading: false,
        },
      };
    }
  }).bind(this);

  getCustomerSubscription = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        itemLoading: true,
      },
    };

    this.saveDataResponse = null;
    this.customerSubscriptionData = null;
    try {
      const response: APIResponse<CustomerSubscription> =
        yield api.getCustomerSubscription(ID);
      this.customerSubscriptionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          itemLoading: false,
        },
      };
    }
  }).bind(this);

  saveCustomerSubscription = flow(function* (
    this: DataStore,
    payload: CustomerSubscription
  ) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveCustomerSubscription(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteCustomerSubscription = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        deleteLoading: true,
      },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteCustomerSubscription(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          deleteLoading: false,
        },
      };
    }
  }).bind(this);

  @observable runSchedulerData: boolean | null = null;
  runScheduler = flow(function* (this: DataStore) {
    this.loading = {
      ...this.loading,
      scheduler: { ...this.loading.scheduler, itemLoading: true },
    };

    this.runSchedulerData = null;
    try {
      this.runSchedulerData = yield api.runScheduler();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        scheduler: { ...this.loading.scheduler, itemLoading: false },
      };
    }
  }).bind(this);

  
@observable TeamsListData: PaginatedListResponse<Team[]> =
initPaginateListResponseGlobal<Team[]>([]);
@observable TeamsData: TeamPayload | null = null;

getTeams = flow(function* (
this: DataStore,
payload: PaginatedListPayload
) {
this.loading = {
  ...this.loading,
  teams: { ...this.loading.teams, listLoading: true },
};
this.TeamsListData = initPaginateListResponseGlobal<Team[]
>([]);

try {
  const response: APIResponse<PaginatedListResponse<Team[]>> = yield api.getTeams(payload);
  this.TeamsListData = response.data;
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    teams: { ...this.loading.teams, listLoading: false },
  };
}
}).bind(this);


saveTeams = flow(function* (this: DataStore, payload: TeamPayload) {
this.loading = {
  ...this.loading,
  teams: { ...this.loading.teams, saveDataLoading: true },
};

this.saveDataResponse = null;
try {
  this.saveDataResponse = yield api.saveTeams(payload);
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    teams: { ...this.loading.teams, saveDataLoading: false },
  };
}
}).bind(this);


getTeam = flow(function* (this: DataStore, ID: string) {
this.loading = {
  ...this.loading,
  team: { ...this.loading.team, itemLoading: true },
};

this.saveDataResponse = null;
this.TeamsData = null;
try {
  const response: APIResponse<TeamPayload> = yield api.getTeam(ID);
  this.TeamsData = response.data;
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    teams: { ...this.loading.teams, itemLoading: false },
  };
}
}).bind(this);

deleteTeam = flow(function* (this: DataStore, ID: string) {
this.loading = {
  ...this.loading,
  teams: { ...this.loading.teams, deleteLoading: true },
};

this.deleteDataResponse = null;
try {
  this.deleteDataResponse = yield api.deleteTeam(ID);
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    teams: { ...this.loading.teams, deleteLoading: false },
  };
}
}).bind(this);

@observable DocumentsListData: PaginatedListResponse<DocumentsListResponse[]> =
  initPaginateListResponseGlobal<DocumentsListResponse[]>([]);

getDocuments = flow(function* (
  this: DataStore,
  payload: GetDocumentsPayload
) {
  this.loading = {
    ...this.loading,
    documents: { ...this.loading.documents, listLoading: true },
  };
  this.DocumentsListData = initPaginateListResponseGlobal<DocumentsListResponse[]>([]);
  try {
    const response: APIResponse<PaginatedListResponse<DocumentsListResponse[]>> = yield api.getDocuments(payload);
    this.DocumentsListData = response.data;
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      documents: { ...this.loading.documents, listLoading: false },
    };
  }
}).bind(this);

saveDocuments = flow(function* (this: DataStore, payload: DocumentsPayload) {
  this.loading = {
    ...this.loading,
    documents: { ...this.loading.documents, saveDataLoading: true },
  };
  this.saveDataResponse = null;
  try {
    const formData = new FormData();
    let docsUrl: any = null;
    if (!payload.id) {
      const file: any = payload.ImageMediaId;
      formData.append(
        "mediaFile",
        file,
        file.name
      );
      docsUrl = yield api.uploadMedia(formData, UploadMediaTypes.Document);
    }
    this.saveDataResponse = yield api.saveDocuments({
      ...payload, ImageMediaId: payload.id ? payload.ImageMediaId  : isGUID(docsUrl.data) ? docsUrl.data : null
    });
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      documents: { ...this.loading.documents, saveDataLoading: false },
    };
  }
}).bind(this);

@observable DocumentsData: DocumentsListResponse | null = null;

getDocument = flow(function* (this: DataStore, ID: string) {
  this.loading = {
    ...this.loading,
    documents: { ...this.loading.documents, itemLoading: true },
  };
  
  this.saveDataResponse = null;
  this.DocumentsData = null;
  try {
    const response: APIResponse<DocumentsListResponse> = yield api.getDocument(ID);
    this.DocumentsData = response.data;
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      documents: { ...this.loading.documents, itemLoading: false },
    };
  }
  }).bind(this);

deleteDocument = flow(function* (this: DataStore, ID: string, recursiveDelete: boolean) {
  this.loading = {
    ...this.loading,
    documents: { ...this.loading.documents, deleteLoading: true },
  };

  this.deleteDataResponse = null;
  try {
    this.deleteDataResponse = yield api.deleteDocuments(ID, recursiveDelete);
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      documents: { ...this.loading.documents, deleteLoading: false },
    };
  }
}).bind(this);

@observable EquipmentsTypesListData: PaginatedListResponse<EquipmentTypesResponse[]> =
  initPaginateListResponseGlobal<EquipmentTypesResponse[]>([]);

  getEquipmentsTypes = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      equipmentTypes: { ...this.loading.equipmentTypes, listLoading: true },
    };
    this.EquipmentsTypesListData = initPaginateListResponseGlobal<EquipmentTypesResponse[]>([]);
    try {
      const response: APIResponse<PaginatedListResponse<EquipmentTypesResponse[]>> = yield api.getEquipmentsTypes(payload);
      this.EquipmentsTypesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipmentTypes: { ...this.loading.equipmentTypes, listLoading: false },
      };
    }
  }).bind(this);

@observable isSideBarVisible: boolean = false;
@observable isSideBarUpdateMode: boolean = false;

handleShowUpdate = flow(function* (this: DataStore, show: boolean, isUpdate: boolean) {
  this.isSideBarVisible = show;
  this.isSideBarUpdateMode = isUpdate;
}).bind(this);

}

const initLoading: DataLoading = {
  listLoading: false,
  saveDataLoading: false,
  deleteLoading: false,
  itemLoading: false,
};
