import { observable, flow, computed } from "mobx";
import moment from "moment";
import * as api from "../utility/dm.api";

export default class EquipmentBIStore {
  @observable errorMessage: string | null = null;

  @observable alarmedEquipmentsBI: number = 0;
  @observable getAlarmedEquipmentsBiLoading: boolean = false;
  getAlarmedEquipmentsBI = flow(function* (
    this: EquipmentBIStore,
    filters: EquipmentBIFilters
  ) {
    this.getAlarmedEquipmentsBiLoading = true;
    this.errorMessage = null;
    this.alarmedEquipmentsBI = 0;
    try {
      const response: APIResponse<number> = yield api.getTotalEquipmentInAlarmsEBI(
        filters
      );
      this.alarmedEquipmentsBI = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.getAlarmedEquipmentsBiLoading = false;
    }
  }).bind(this);

  @observable equipmentTypesBI: number = 0;
  @observable equipmentTypesBILoading: boolean = false;
  getEquipmentTypesBI = flow(function* (
    this: EquipmentBIStore,
    filters: EquipmentBIFilters
  ) {
    this.equipmentTypesBILoading = true;
    this.errorMessage = null;
    this.equipmentTypesBI = 0;
    try {
      const response: APIResponse<number> = yield api.getTotalEquipmentTypesEBI(
        filters
      );
      this.equipmentTypesBI = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.equipmentTypesBILoading = false;
    }
  }).bind(this);

  @observable totalEquipmentBI: number = 0;
  @observable totalEquipmentBILoading: boolean = false;
  getTotalEquipmentBI = flow(function* (
    this: EquipmentBIStore,
    filters: EquipmentBIFilters
  ) {
    this.totalEquipmentBILoading = true;
    this.errorMessage = null;
    this.totalEquipmentBI = 0;
    try {
      const response: APIResponse<number> = yield api.getTotalEquipmentsEBI(
        filters
      );
      this.totalEquipmentBI = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.totalEquipmentBILoading = false;
    }
  }).bind(this);

  @observable equipmentBiHealthChart: ReportXY[] = [];
  @observable equipmentBiHealthChartLoading: boolean = false;
  getEquipmentBiHealthChart = flow(function* (
    this: EquipmentBIStore,
    filters: EquipmentBIFilters
  ) {
    this.equipmentBiHealthChartLoading = true;
    this.errorMessage = null;
    this.equipmentBiHealthChart = [];
    try {
      const response: APIResponse<
        ReportXY[]
      > = yield api.getHealthIndicatorPerDayEBI(filters);
      this.equipmentBiHealthChart = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.equipmentBiHealthChartLoading = false;
    }
  }).bind(this);
  @computed
  get equipmentBiHealthChartComputed() {
    return this.equipmentBiHealthChart.map((x: ReportXY) => [
      moment(x.x).valueOf(),
      x.y,
    ]);
  }

  @observable equipmentHealthPercentageBI: number = 0;
  @observable equipmentHealthPercentageBILoading: boolean = false;
  getEquipmentHealthPercentageBI = flow(function* (
    this: EquipmentBIStore,
    filters: EquipmentBIFilters
  ) {
    this.equipmentHealthPercentageBILoading = true;
    this.errorMessage = null;
    this.equipmentHealthPercentageBI = 0;
    try {
      const response: APIResponse<number> = yield api.getHealthIndicatorPercentageEBI(
        filters
      );
      this.equipmentHealthPercentageBI = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.equipmentHealthPercentageBILoading = false;
    }
  }).bind(this);

  @observable equipmentByType: EquipmentsByTypes[] = [];
  @observable equipmentByTypeLoading: boolean = false;
  getEquipmentByType = flow(function* (
    this: EquipmentBIStore,
    filters: EquipmentBIFilters
  ) {
    this.equipmentByTypeLoading = true;
    this.errorMessage = null;
    this.equipmentByType = [];
    try {
      const response: APIResponse<
        EquipmentsByTypes[]
      > = yield api.getEquipmentByTypesEBI(filters);
      this.equipmentByType = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.equipmentByTypeLoading = false;
    }
  }).bind(this);

  @observable equipmentByStatusData: EquipmentsByStatusResponse[] = [];
  @observable equipmentByStatusLoading: boolean = false;
  getEquipmentsByStatus = flow(function* (
    this: EquipmentBIStore,
    payload: EquipmentsByStatusPayload
  ) {
    this.equipmentByStatusLoading = true;
    this.errorMessage = null;
    this.equipmentByStatusData = [];
    try {
      const response: APIResponse<
      EquipmentsByStatusResponse[]
      > = yield api.getEquipmentsStatusByTypes(payload);
      this.equipmentByStatusData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.equipmentByStatusLoading = false;
    }
  }).bind(this);
}
